<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center" style="min-width: 300px;">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-gear fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Dịch vụ</h2>
            <h6 class="m-0 text-dark fw-light">
                Hệ thống
            </h6>
        </div>
    </div>
    <div class="col-lg-6 col-sm-6 col-12">
    </div>
    <div class="col-lg-3 col-sm-6 col-12">
        <div class="input-group">
            <input class="form-control" type="text" placeholder="Tìm ..." [(ngModel)]="searchString"
                (keyup.enter)="onSearch()">
            <a class="btn btn-success" title="Tìm..." (click)="onSearch()">
                <i class="bi bi-search"></i>
            </a>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a class="btn btn-success" title="In biểu mẫu">
                <i class="bi bi-printer"></i>
            </a>
            <a class="btn btn-success" title="Download excel">
                <i class="bi bi-cloud-download"></i>
            </a>
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4 card-info">
                <div class="card-header">
                    <h5 class="card-title text-white">Danh sách <span *ngIf="DichVuService.list">({{DichVuService.list.length}} items)</span></h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table class="table table-striped m-0">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>ID</th>
                                        <th>Loại Dịch vụ</th>
                                        <th>Code</th>
                                        <th>Dịch vụ</th>
                                        <th>Ghi chú</th>
                                        <th>Sắp xếp</th>
                                        <th>Kích hoạt</th>
                                        <th>Lưu</th>
                                        <th>Xoá</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let element of DichVuService.list; index as i">
                                        <td>{{i + 1}}</td>
                                        <td>{{element.ID}}</td>
                                        <td>
                                            <select class="form-select" name="ParentID{{element.ID}}"
                                                [(ngModel)]="element.ParentID">
                                                <option *ngFor="let item of LoaiDichVuService.list;" [value]="item.ID">
                                                    {{item.Name}}</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input class="form-control" type="text" placeholder="Code"
                                                name="Code{{element.ID}}" [(ngModel)]="element.Code">
                                        </td>
                                        <td><input class="form-control" type="text" placeholder="Tỉnh"
                                                name="Name{{element.ID}}" [(ngModel)]="element.Name"></td>
                                        <td><input class="form-control" type="text" placeholder="Ghi chú"
                                                name="Note{{element.ID}}" [(ngModel)]="element.Note"></td>
                                        <td><input class="form-control" type="number" placeholder="0"
                                                name="SortOrder{{element.ID}}" [(ngModel)]="element.SortOrder"
                                                style="text-align: right;"></td>
                                        <td>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox"
                                                    name="Active{{element.ID}}" [(ngModel)]="element.Active">
                                            </div>
                                        </td>
                                        <td>
                                            <a class="btn btn-info" (click)="onSave(element)"><i
                                                    class="bi bi-sd-card"></i>
                                            </a>
                                        </td>
                                        <td>
                                            <a class="btn btn-danger" (click)="onDelete(element)"
                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>