<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center" style="min-width: 300px;">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-emoji-sunglasses fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">{{NhanVienService.formDataLogin.Name}}</h2>
            <h6 class="m-0 text-dark fw-light">
                Nhân viên đăng nhập
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-1" data-bs-toggle="tab" href="#1" role="tab"
                                    aria-controls="1" aria-selected="false">
                                    <span class="badge bg-info">Doanh thu</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-2" data-bs-toggle="tab" href="#2" role="tab"
                                    aria-controls="2" aria-selected="false">
                                    <span class="badge bg-danger">Tài khoản</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-3" data-bs-toggle="tab" href="#3" role="tab"
                                    aria-controls="3" aria-selected="false">
                                    <span class="badge bg-success">Khu vực</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-4" data-bs-toggle="tab" href="#4" role="tab"
                                    aria-controls="4" aria-selected="true">
                                    <span class="badge bg-primary">Thông tin</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade" id="1" role="tabpanel">
                                <h1 class="display-1 text-center text-danger p-5">
                                    1
                                </h1>
                            </div>
                            <div class="tab-pane fade" id="2" role="tabpanel">
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Tài khoản
                                            <span
                                                *ngIf="NhanVienTaiKhoanService.list">({{NhanVienTaiKhoanService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table class="table table-striped m-0">
                                                    <thead>
                                                        <tr>
                                                            <th>No</th>
                                                            <th>Tài khoản</th>
                                                            <th>Ghi chú</th>
                                                            <th>Sắp xếp</th>
                                                            <th>Kích hoạt</th>
                                                            <th>Lưu</th>
                                                            <th>Xoá</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let element of NhanVienTaiKhoanService.list; index as i">
                                                            <td>{{i + 1}}</td>
                                                            <td>
                                                                <input class="form-control" type="text"
                                                                    placeholder="Tài khoản" name="Code{{element.ID}}"
                                                                    [(ngModel)]="element.Code">
                                                            </td>
                                                            <td><input class="form-control" type="text"
                                                                    placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                    [(ngModel)]="element.Note"></td>
                                                            <td><input class="form-control" type="number"
                                                                    placeholder="0" name="SortOrder{{element.ID}}"
                                                                    [(ngModel)]="element.SortOrder"
                                                                    style="text-align: right;"></td>
                                                            <td>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        name="Active{{element.ID}}"
                                                                        [(ngModel)]="element.Active">
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <a class="btn btn-info"
                                                                    (click)="onSaveNhanVienTaiKhoan(element)"><i
                                                                        class="bi bi-sd-card"></i>
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a class="btn btn-danger"
                                                                    (click)="onDeleteNhanVienTaiKhoan(element)"
                                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="3" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-5 col-sm-12 col-12">
                                        <div class="card mb-4 card-info">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">Phân quyền khu vực
                                                    <span
                                                        *ngIf="NhanVienKhuVucService.list">({{NhanVienKhuVucService.list.length}}
                                                        items)</span>
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table class="table table-striped m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>Huyện</th>
                                                                    <th>Xã</th>
                                                                    <th>Phân quyền</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let element of NhanVienKhuVucService.list; index as i">
                                                                    <td>{{i + 1}}</td>
                                                                    <td>
                                                                        <div *ngFor="let item of HuyenService.list;">
                                                                            <div *ngIf="item.ID==element.HuyenID">
                                                                                {{item.Name}}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div *ngFor="let item of XaService.list;">
                                                                            <div *ngIf="item.ID==element.XaID">
                                                                                {{item.Name}}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="form-check form-check-inline">
                                                                            <input class="form-check-input"
                                                                                type="checkbox"
                                                                                (change)="onNhanVienKhuVucActiveChange(element)"
                                                                                name="Active{{element.ID}}"
                                                                                [(ngModel)]="element.Active">
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-sm-12 col-12">
                                        <div class="card mb-4 card-light">
                                            <div class="card-header">
                                                <h5 class="card-title">Phân quyền doanh nghiệp
                                                    <span
                                                        *ngIf="DoanhNghiepService.listSearch">({{DoanhNghiepService.listSearch.length}}
                                                        items)</span>
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="row gx-4">
                                                    <div class="col-lg-10 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tìm ..." [(ngModel)]="searchString"
                                                                (keyup.enter)="onSearch()">
                                                            <a class="btn btn-success" title="Tìm..."
                                                                (click)="onSearch()">
                                                                <i class="bi bi-search"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2 col-sm-12 col-12">
                                                        <a class="btn btn-info" title="Lưu thay đổi" (click)="onSave()">
                                                            <i class="bi bi-sd-card"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="row gx-4">
                                                    <div class="table-outer">
                                                        <div class="table-responsive">
                                                            <table class="table table-striped m-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>No</th>
                                                                        <th>MST</th>
                                                                        <th>Doanh nghiệp</th>
                                                                        <th>Huyện</th>
                                                                        <th>Xã</th>
                                                                        <th>Nhân viên</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr
                                                                        *ngFor="let element of DoanhNghiepService.listSearch; index as i">
                                                                        <td>{{i + 1}}</td>
                                                                        <td>{{element.Code}}</td>
                                                                        <td><a title="{{element.Name}}" target="_blank"
                                                                                href="{{URLSubDoanhNghiep}}/{{element.ID}}"><b>{{element.Name}}</b></a>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                *ngFor="let item of HuyenService.list;">
                                                                                <div *ngIf="item.ID==element.HuyenID">
                                                                                    {{item.Name}}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div *ngFor="let item of XaService.list;">
                                                                                <div *ngIf="item.ID==element.XaID">
                                                                                    {{item.Name}}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                *ngFor="let item of NhanVienService.list;">
                                                                                <div
                                                                                    *ngIf="item.ID==element.NhanVienID">
                                                                                    {{item.Name}}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade show active" id="4" role="tabpanel">
                                <div class="card mb-4 card-light">
                                    <div class="card-header">
                                        <h5 class="card-title">Thông tin nhân viên
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
                                            <div class="row gx-4">
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <a title="Danh sách" class="btn btn-success"
                                                        href="javascript:window.open('','_self').close();"><i
                                                            class="bi bi-arrow-left-square"></i></a>&nbsp;&nbsp;&nbsp;
                                                    <a title="Thêm mới" href="{{URLSub}}/0" class="btn btn-success"><i
                                                            class="bi bi-plus-square-dotted"></i></a>&nbsp;&nbsp;&nbsp;
                                                    <button [disabled]="!NhanVienService.formDataLogin.Name"
                                                        title="Lưu thay đổi" type="submit" class="btn btn-info"><i
                                                            class="bi bi-sd-card"></i></button>
                                                </div>
                                            </div>
                                            <input name="ID" [(ngModel)]="NhanVienService.formDataLogin.ID" type="hidden">
                                            <div class="row gx-4">
                                                <div class="col-lg-4 col-sm-12 col-12">
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Email</label>
                                                            <input name="Email"
                                                                [(ngModel)]="NhanVienService.formDataLogin.Email"
                                                                placeholder="Email" type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Mật khẩu (Ít nhất 7 ký tự)</label>
                                                            <input name="MatKhau"
                                                                [(ngModel)]="NhanVienService.formDataLogin.MatKhau"
                                                                placeholder="Mật khẩu" type="password" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Nhân viên</label>
                                                            <input name="Name"
                                                                [(ngModel)]="NhanVienService.formDataLogin.Name"
                                                                placeholder="Nhân viên" type="text"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Mã nhân viên</label>
                                                            <input name="Code"
                                                                [(ngModel)]="NhanVienService.formDataLogin.Code"
                                                                placeholder="Mã nhân viên" type="text"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Điện thoại</label>
                                                            <input name="DienThoai"
                                                                [(ngModel)]="NhanVienService.formDataLogin.DienThoai"
                                                                placeholder="Điện thoại" type="text"
                                                                class="form-control">
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-lg-4 col-sm-12 col-12">
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Phòng ban</label>
                                                            <select class="form-select" name="ParentID"
                                                                [(ngModel)]="NhanVienService.formDataLogin.ParentID">
                                                                <option *ngFor="let item of PhongBanService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">CCCD</label>
                                                            <input name="CCCD"
                                                                [(ngModel)]="NhanVienService.formDataLogin.CCCD"
                                                                placeholder="CCCD" type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Địa chỉ</label>
                                                            <input name="DiaChi"
                                                                [(ngModel)]="NhanVienService.formDataLogin.DiaChi"
                                                                placeholder="Địa chỉ" type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Sắp xếp</label>
                                                            <input name="SortOrder"
                                                                [(ngModel)]="NhanVienService.formDataLogin.SortOrder"
                                                                style="text-align: right;" placeholder="0" type="number"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="form-check form-check-inline mb-3">
                                                            <input type="checkbox" class="form-check-input"
                                                                name="Active"
                                                                [(ngModel)]="NhanVienService.formDataLogin.Active" />
                                                            <label class="form-check-label" for="Active">Kích
                                                                hoạt</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-sm-12 col-12">
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Hình đại diện</label>
                                                            <input type="file"
                                                                (change)="changeImage($event.target.files)">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <img class="img-fluid rounded" title="{{NhanVienService.formDataLogin.Note}}" alt="NhanVienService.formDataLogin.Note"
                                                                [src]="NhanVienService.formDataLogin.Note">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>