<nav id="sidebar" class="sidebar-wrapper">
      <div class="app-brand p-3 mb-3">
            <a href="#">
                  <img src="https://crm.vnptvungtau.vn/image/logo.png" class="logo" alt="VNPT Bà Rịa - Vũng Tàu" />Bà
                  Rịa - Vũng Tàu
            </a>
      </div>
      <div class="sidebarMenuScroll">
            <ul class="sidebar-menu">
                  <li (click)="MenuClick(itemParent)"
                        [ngClass]="itemParent.Active == true ? 'treeview active current-page': 'treeview'"
                        *ngFor="let itemParent of MenuService.ListParent;">
                        <a style="cursor: pointer;">
                              <i class="{{itemParent.Note}}"></i>
                              <span class="menu-text">{{itemParent.Name}}</span>
                        </a>
                        <ul [ngClass]="itemParent.Active == true ? 'treeview-menu menu-open': 'treeview-menu'">
                              <li *ngFor="let itemChild of itemParent.ListChild;">
                                    <a [ngStyle]="{'font-weight': itemChild.Active == true ? 'bold' : ''}"
                                          [ngClass]="itemChild.Active == true ? 'active-sub': ''"
                                          href="{{itemChild.Display}}">{{itemChild.Name}}</a>
                              </li>
                        </ul>
                  </li>
                  <!-- <li>
                        <a href="{{domainName}}Homepage" title="Tổng hợp">
                              <i class="bi bi-bar-chart"></i>
                              <span class="menu-text">Tổng hợp</span>
                        </a>
                  </li>
                  <li>
                        <a href="{{domainName}}DashboardCA" title="VNPT-CA & SMART-CA">
                              <i class="bi bi-bar-chart"></i>
                              <span class="menu-text">VNPT-CA & SMART-CA</span>
                        </a>
                  </li>

                  <li>
                        <a href="{{domainName}}DoanhNghiep" title="Khách hàng">
                              <i class="bi bi-bank2"></i>
                              <span class="menu-text">Khách hàng</span>
                        </a>
                  </li>
                  <li>
                        <a href="{{domainName}}NhanVien" title="Nhân viên">
                              <i class="bi bi-emoji-sunglasses"></i>
                              <span class="menu-text">Nhân viên</span>
                        </a>
                  </li>
                  <li>
                        <a href="{{domainName}}PhongBan" title="Phòng ban">
                              <i class="bi bi-emoji-sunglasses"></i>
                              <span class="menu-text">Phòng ban</span>
                        </a>
                  </li>
                  <li>
                        <a href="{{domainName}}Tinh" title="Hệ thống">
                              <i class="bi bi-gear"></i>
                              <span class="menu-text">Hệ thống</span>
                        </a>
                  </li>
                  <li>
                        <a href="{{domainName}}Upload" title="Upload">
                              <i class="bi bi-cloud-arrow-up"></i>
                              <span class="menu-text">Upload</span>
                        </a>
                  </li> -->
            </ul>
      </div>
      <div class="sidebar-settings gap-2 d-lg-flex d-none">
            <a href="{{domainName}}NhanVienDangNhap" class="settings-icon bg-success" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="Tài khoản">
                  <i class="bi bi-person"></i>
            </a>
            <a href="{{domainName}}NhanVienDangNhap" class="settings-icon bg-success" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="Đổi mật khẩu">
                  <i class="bi bi-shield-lock"></i>
            </a>
            <a style="cursor: pointer;" (click)="onLogout()" class="settings-icon bg-danger" data-bs-toggle="tooltip"
                  data-bs-placement="top" title="Đăng xuất">
                  <i class="bi bi-power"></i>
            </a>
      </div>
</nav>
<div class="app-container">
      <div class="app-header d-flex align-items-center">
            <div class="d-flex">
                  <button class="pin-sidebar">
                        <i class="bi bi-list lh-1"></i>
                  </button>
            </div>
            <div class="app-brand-sm d-lg-none d-flex">
                  <a href="#" title="TRUNG TÂM KINH DOANH VNPT BÀ RỊA VŨNG TÀU">
                        <img src="assets/images/logo.svg" class="logo" alt="TRUNG TÂM KINH DOANH VNPT BÀ RỊA VŨNG TÀU">
                  </a>
            </div>
            <div class="search-container d-lg-block d-none mx-3">
                  <input type="text" class="form-control"
                        placeholder="VNPT Bà Rịa - Vũng Tàu | TRUNG TÂM KINH DOANH VNPT BÀ RỊA VŨNG TÀU" />
            </div>
            <div class="header-actions">
                  <div class="dropdown ms-4">
                        <a id="userSettings" class="dropdown-toggle d-flex py-2 align-items-center" href="#!"
                              role="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <img *ngIf="NhanVienService.formDataLogin.Note"
                                    src="{{NhanVienService.formDataLogin.Note}}" class="rounded-4 img-3x"
                                    alt="{{NhanVienService.formDataLogin.Name}}"
                                    title="{{NhanVienService.formDataLogin.Name}}" />
                              <span
                                    class="ms-2 text-truncate d-lg-block d-none">{{NhanVienService.formDataLogin.Name}}</span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end shadow-lg">
                              <a class="dropdown-item d-flex align-items-center"
                                    href="{{domainName}}NhanVienDangNhap"><i class="bi bi-person fs-4 me-2"></i>Tài
                                    khoản</a>
                              <a class="dropdown-item d-flex align-items-center"
                                    href="{{domainName}}NhanVienDangNhap"><i class="bi bi-shield-lock fs-4 me-2"></i>Đổi
                                    mật khẩu</a>
                              <div class="mx-3 mt-2 d-grid">
                                    <a style="cursor: pointer;" (click)="onLogout()" class="btn btn-primary">Đăng
                                          xuất</a>
                              </div>
                        </div>
                  </div>
                  <div class="d-flex">
                        <button class="toggle-sidebar">
                              <i class="bi bi-list lh-1"></i>
                        </button>
                  </div>
            </div>
      </div>
      <router-outlet></router-outlet>
</div>