<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center" style="min-width: 300px;">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-emoji-sunglasses fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Phân quyền</h2>
            <h6 class="m-0 text-dark fw-light">
                Nhân viên
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-NhanVienMenu" data-bs-toggle="tab"
                                    href="#NhanVienMenu" role="tab" aria-controls="NhanVienMenu" aria-selected="true">
                                    <span class="badge bg-warning" style="font-size: 18px;">Phân quyền Menu</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-Menu" data-bs-toggle="tab" href="#Menu" role="tab"
                                    aria-controls="Menu" aria-selected="false">
                                    <span class="badge bg-info" style="font-size: 18px;">Menu</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="NhanVienMenu" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <select class="form-select" name="nhanVienID" [(ngModel)]="nhanVienID">
                                            <option *ngFor="let item of NhanVienService.list;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="GetNhanVienMenuToListAsync()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input"
                                                type="checkbox"
                                                (change)="NhanVienMenuActiveAllChange()"
                                                name="activeAll"
                                                [(ngModel)]="NhanVienMenuActiveAll">
                                        </div>        
                                    </div>      
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <div class="card mb-4 card-warning">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">Phân quyền Menu
                                                    <span
                                                        *ngIf="NhanVienMenuService.list">({{NhanVienMenuService.list.length}}
                                                        items)</span>
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table class="table table-striped m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>Menu</th>
                                                                    <th>Phân quyền</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let element of NhanVienMenuService.list; index as i">
                                                                    <td>{{i + 1}}</td>
                                                                    <td>
                                                                        <div *ngFor="let item of MenuService.list;">
                                                                            <div *ngIf="item.ID==element.MenuID">
                                                                                {{item.Name}}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="form-check form-check-inline">
                                                                            <input class="form-check-input"
                                                                                type="checkbox"
                                                                                (change)="onNhanVienMenuActiveChange(element)"
                                                                                name="Active{{element.ID}}"
                                                                                [(ngModel)]="element.Active">
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="Menu" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="searchString" placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="onSearchMenu()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="onSearchMenu()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span *ngIf="MenuService.list">({{MenuService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #sortMenu="matSort"
                                                    [dataSource]="dataSourceMenu" class="table table-striped m-0">
                                                    <ng-container matColumnDef="No">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            No.
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{paginatorMenu.pageSize *
                                                            paginatorMenu.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ParentID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Parent
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select" name="element.ParentID"
                                                                [(ngModel)]="element.ParentID">
                                                                <option value="0">
                                                                    Root</option>
                                                                <option
                                                                    *ngFor="let item of MenuService.list001;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tiêu đề" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Display">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>URL</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="URL"
                                                                name="Display{{element.ID}}" [(ngModel)]="element.Display">
                                                        </td>
                                                    </ng-container>                                                    
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Icon
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Icon" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SortOrder">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="SortOrder{{element.ID}}"
                                                                [(ngModel)]="element.SortOrder"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info" (click)="onSaveMenu(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger" (click)="onDeleteMenu(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row *matHeaderRowDef="MenuService.displayColumns">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: MenuService.displayColumns;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginatorMenu="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="1000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>