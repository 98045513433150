<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center" style="min-width: 300px;">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-bar-chart fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Email</h2>
            <h6 class="m-0 text-dark fw-light">
                CA
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a *ngIf="DoanhNghiepDichVuCAService.NhanVienIDLogin==1" title="Lưu thay đổi" (click)="onSubmit()" class="btn btn-info"><i class="bi bi-sd-card"></i></a>
            <a title="Đóng" class="btn btn-success" (click)="onClose()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">      
        <div class="col-lg-12 col-sm-12 col-12">
            <!-- <div class="col-lg-12 col-sm-12 col-12">
                <label class="form-label">Khách hàng</label>
                <input name="Name" [(ngModel)]="DoanhNghiepDichVuCAService.formData.Name" placeholder="Khách hàng"
                    type="text" class="form-control">
            </div>  
            <div class="col-lg-12 col-sm-12 col-12">
                <label class="form-label">Mã số thuế</label>
                <input name="Note" [(ngModel)]="DoanhNghiepDichVuCAService.formData.Note" placeholder="Mã số thuế"
                    type="text" class="form-control">
            </div>  
            <div class="col-lg-12 col-sm-12 col-12">
                <label class="form-label">Điện thoại</label>
                <input name="DienThoai" [(ngModel)]="DoanhNghiepDichVuCAService.formData.DienThoai" placeholder="Điện thoại"
                    type="text" class="form-control">
            </div>   -->
            <div class="col-lg-12 col-sm-12 col-12">
                <label class="form-label">Email</label>
                <input name="Email" [(ngModel)]="DoanhNghiepDichVuCAService.formData.Email" placeholder="Email"
                    type="text" class="form-control">
            </div>           
        </div>        
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>