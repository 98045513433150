<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center" style="min-width: 300px;">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-bank2 fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Khách hàng</h2>
            <h6 class="m-0 text-dark fw-light">
                Khách hàng
            </h6>
        </div>
    </div>
    <div class="col-lg-2 col-sm-6 col-12">

    </div>
    <div class="col-lg-2 col-sm-6 col-12">
        <select class="form-select" name="huyenID" [(ngModel)]="huyenID" (change)="onChangeHuyenID()">
            <option [value]="0">
                Tất cả</option>
            <option *ngFor="let item of HuyenService.list;" [value]="item.ID">
                {{item.Name}}</option>
        </select>
    </div>
    <div class="col-lg-2 col-sm-6 col-12">
        <select class="form-select" name="xaID" [(ngModel)]="xaID">
            <option [value]="0">
                Tất cả</option>
            <option *ngFor="let item of XaService.list;" [value]="item.ID">
                {{item.Name}}</option>
        </select>
    </div>
    <div class="col-lg-3 col-sm-6 col-12">
        <div class="input-group">
            <input class="form-control" type="text" placeholder="Tìm ..." [(ngModel)]="searchString"
                (keyup.enter)="onSearch()">
            <a class="btn btn-success" title="Tìm..." (click)="onSearch()">
                <i class="bi bi-search"></i>
            </a>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <!-- <a class="btn btn-success" title="In biểu mẫu">
                <i class="bi bi-printer"></i>
            </a>
            <a class="btn btn-success" title="Download excel">
                <i class="bi bi-cloud-download"></i>
            </a> -->
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4 card-info">
                <div class="card-header">
                    <h5 class="card-title text-white">Danh sách
                        <span *ngIf="DoanhNghiepService.list">({{DoanhNghiepService.list.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table mat-table matSort #sort="matSort" [dataSource]="dataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="No">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        No.
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;">
                                        {{paginator.pageSize *
                                        paginator.pageIndex + i + 1}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Khách hàng
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="link-primary" target="_blank" title="{{element.Name}}"
                                            href="{{URLSub}}/{{element.ID}}"><b>{{element.Name}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số thuế/CCCD
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Code}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="UserName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>UserName
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.UserName}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Description">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Mô tả
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Description}}
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="DoanhNghiepService.displayColumns">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: DoanhNghiepService.displayColumns;">
                                </tr>
                            </table>
                            <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                [showFirstLastButtons]></mat-paginator>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>