<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center" style="min-width: 300px;">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-bar-chart fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Tổng hợp</h2>
            <h6 class="m-0 text-dark fw-light">
                Dashboard
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-1" data-bs-toggle="tab" href="#1" role="tab"
                                    aria-controls="1" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 14px; width: 120px;">Tổng hợp</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-2" data-bs-toggle="tab" href="#2" role="tab"
                                    aria-controls="2" aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 14px; width: 120px;">Dịch vụ</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-3" data-bs-toggle="tab" href="#3" role="tab"
                                    aria-controls="3" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 14px; width: 120px;">Phòng ban</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-4" data-bs-toggle="tab" href="#4" role="tab"
                                    aria-controls="4" aria-selected="false">
                                    <span class="badge bg-primary" style="font-size: 14px; width: 120px;">Nhân viên</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="1" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <select class="form-select" name="huyenID" [(ngModel)]="huyenID"
                                            (change)="onChangeHuyenID()">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of HuyenService.list;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>         
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="year" [(ngModel)]="year">
                                            <option *ngFor="let item of DownloadService.listYear;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="month" [(ngModel)]="month">
                                            <option *ngFor="let item of DownloadService.listMonth;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>           
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <input [(ngModel)]="searchString" placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="onSearchReportVNPT001()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="onSearchReportVNPT001()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-success" title="Download excel"
                                            (click)="onDownloadExcelFileReportVNPT001()"><i
                                                class="bi bi-cloud-download"></i></a>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div name="DoanhThu"
                                            style="font-weight: bold; color: limegreen; text-align: right; font-size: 20px;">
                                            {{doanhThuTongHop | number:
                                            '1.0-0'}}
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Tổng hợp
                                            <span
                                                *ngIf="ReportService.listReportVNPT001">({{ReportService.listReportVNPT001.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #sortReportVNPT001="matSort"
                                                    [dataSource]="dataSourceReportVNPT001"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="No">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            No.
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{paginatorReportVNPT001.pageSize *
                                                            paginatorReportVNPT001.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doanh
                                                            nghiệp</th>
                                                        <td mat-cell *matCellDef="let element"> <a
                                                                title="{{element.Name}}" class="link-primary"
                                                                target="_blank"
                                                                href="{{URLSub}}/{{element.ID}}"><b>{{element.Name}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="HuyenName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Huyện</th>
                                                        <td mat-cell *matCellDef="let element"> {{element.HuyenName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tổng thu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu |
                                                                number:
                                                                '1.0-0'}}</b>
                                                            <br />
                                                            <span *ngIf="element.ChenhLech>=0"
                                                                style="color: green; text-align: right; font-weight: bold;">
                                                                (<i class="bi bi-caret-up-fill"></i> {{element.ChenhLech | number:
                                                                '1.0-0'}})</span>
                                                            <span *ngIf="element.ChenhLech<0"
                                                                style="color: red; text-align: right; font-weight: bold;">
                                                                (<i class="bi bi-caret-down-fill"></i> {{element.ChenhLech | number:
                                                                '1.0-0'}})</span>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu01">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cố định
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu01 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                            <br />
                                                            <span *ngIf="element.ChenhLech01>=0"
                                                                style="color: green; text-align: right; font-weight: bold;">
                                                                (<i class="bi bi-caret-up-fill"></i> {{element.ChenhLech01 | number:
                                                                '1.0-0'}})</span>
                                                            <span *ngIf="element.ChenhLech01<0"
                                                                style="color: red; text-align: right; font-weight: bold;">
                                                                (<i class="bi bi-caret-down-fill"></i> {{element.ChenhLech01 | number:
                                                                '1.0-0'}})</span>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu03">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Di động
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu03 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                            <br />
                                                            <span *ngIf="element.ChenhLech02>=0"
                                                                style="color: green; text-align: right; font-weight: bold;">
                                                                (<i class="bi bi-caret-up-fill"></i> {{element.ChenhLech02 | number:
                                                                '1.0-0'}})</span>
                                                            <span *ngIf="element.ChenhLech02<0"
                                                                style="color: red; text-align: right; font-weight: bold;">
                                                                (<i class="bi bi-caret-down-fill"></i> {{element.ChenhLech02 | number:
                                                                '1.0-0'}})</span>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu05">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fiber
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu05 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                            <br />
                                                            <span *ngIf="element.ChenhLech03>=0"
                                                                style="color: green; text-align: right; font-weight: bold;">
                                                                (<i class="bi bi-caret-up-fill"></i> {{element.ChenhLech03 | number:
                                                                '1.0-0'}})</span>
                                                            <span *ngIf="element.ChenhLech03<0"
                                                                style="color: red; text-align: right; font-weight: bold;">
                                                                (<i class="bi bi-caret-down-fill"></i> {{element.ChenhLech03 | number:
                                                                '1.0-0'}})</span>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu07">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>MyTV
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu07 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                            <br />
                                                            <span *ngIf="element.ChenhLech04>=0"
                                                                style="color: green; text-align: right; font-weight: bold;">
                                                                (<i class="bi bi-caret-up-fill"></i> {{element.ChenhLech04 | number:
                                                                '1.0-0'}})</span>
                                                            <span *ngIf="element.ChenhLech04<0"
                                                                style="color: red; text-align: right; font-weight: bold;">
                                                                (<i class="bi bi-caret-down-fill"></i> {{element.ChenhLech04 | number:
                                                                '1.0-0'}})</span>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu09">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>An toàn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu09 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                            <br />
                                                            <span *ngIf="element.ChenhLech05>=0"
                                                                style="color: green; text-align: right; font-weight: bold;">
                                                                (<i class="bi bi-caret-up-fill"></i> {{element.ChenhLech05 | number:
                                                                '1.0-0'}})</span>
                                                            <span *ngIf="element.ChenhLech05<0"
                                                                style="color: red; text-align: right; font-weight: bold;">
                                                                (<i class="bi bi-caret-down-fill"></i> {{element.ChenhLech05 | number:
                                                                '1.0-0'}})</span>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu11">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>CNTT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu11 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                            <br />
                                                            <span *ngIf="element.ChenhLech06>=0"
                                                                style="color: green; text-align: right; font-weight: bold;">
                                                                (<i class="bi bi-caret-up-fill"></i> {{element.ChenhLech06 | number:
                                                                '1.0-0'}})</span>
                                                            <span *ngIf="element.ChenhLech06<0"
                                                                style="color: red; text-align: right; font-weight: bold;">
                                                                (<i class="bi bi-caret-down-fill"></i> {{element.ChenhLech06 | number:
                                                                '1.0-0'}})</span>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu13">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Khác
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu13 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                            <br />
                                                            <span *ngIf="element.ChenhLech07>=0"
                                                                style="color: green; text-align: right; font-weight: bold;">
                                                                (<i class="bi bi-caret-up-fill"></i> {{element.ChenhLech07 | number:
                                                                '1.0-0'}})</span>
                                                            <span *ngIf="element.ChenhLech07<0"
                                                                style="color: red; text-align: right; font-weight: bold;">
                                                                (<i class="bi bi-caret-down-fill"></i> {{element.ChenhLech07 | number:
                                                                '1.0-0'}})</span>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.displayColumnsReportVNPT001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.displayColumnsReportVNPT001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginatorReportVNPT001="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="2" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <select class="form-select" name="huyenID" [(ngModel)]="huyenID">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of HuyenService.list;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <select class="form-select" name="dichVuID" [(ngModel)]="dichVuID">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of DichVuService.list;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="year" [(ngModel)]="year">
                                            <option *ngFor="let item of DownloadService.listYear;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <input [(ngModel)]="searchString" placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="onSearchReportVNPT003()">
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="onSearchReportVNPT003()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-success" title="Download excel"
                                            (click)="onDownloadExcelFileReportVNPT003()"><i
                                                class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp;
                                        <span name="DoanhThu"
                                            style="font-weight: bold; color: limegreen; text-align: right; font-size: 20px;">
                                            {{doanhThuDichVu | number:
                                            '1.0-0'}}
                                        </span>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Dịch vụ
                                            <span
                                                *ngIf="ReportService.listReportVNPT003">({{ReportService.listReportVNPT003.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #sortReportVNPT003="matSort"
                                                    [dataSource]="dataSourceReportVNPT003"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="No">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            No.
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{paginatorReportVNPT003.pageSize *
                                                            paginatorReportVNPT003.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doanh
                                                            nghiệp</th>
                                                        <td mat-cell *matCellDef="let element"> <a
                                                                title="{{element.Name}}" class="link-primary"
                                                                target="_blank"
                                                                href="{{URLSub}}/{{element.ID}}"><b>{{element.Name}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="HuyenName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Huyện</th>
                                                        <td mat-cell *matCellDef="let element"> {{element.HuyenName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Năm
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu01">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>01
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu01 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu02">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>02
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu02 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu03">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>03
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu03 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu04">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>04
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu04 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu05">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>05
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu05 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu06">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>06
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu06 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu07">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>07
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu07 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu08">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>08
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu08 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu09">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>09
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu09 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu10">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>10
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu10 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu11">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>11
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu11 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu12">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>12
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu12 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.displayColumnsReportVNPT003">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.displayColumnsReportVNPT003;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginatorReportVNPT003="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="3" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <select class="form-select" name="phongBanID" [(ngModel)]="phongBanID">
                                            <option *ngFor="let item of PhongBanService.list;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="year" [(ngModel)]="year">
                                            <option *ngFor="let item of DownloadService.listYear;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <input [(ngModel)]="searchString" placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="onSearchReportVNPT004()">
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="onSearchReportVNPT004()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-success" title="Download excel"
                                            (click)="onDownloadExcelFileReportVNPT004()"><i
                                                class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp;
                                        <span name="DoanhThu"
                                            style="font-weight: bold; color: limegreen; text-align: right; font-size: 20px;">
                                            {{doanhThuPhongBan | number:
                                            '1.0-0'}}
                                        </span>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Phòng ban
                                            <span
                                                *ngIf="ReportService.listReportVNPT004">({{ReportService.listReportVNPT004.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #sortReportVNPT004="matSort"
                                                    [dataSource]="dataSourceReportVNPT004"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="No">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            No.
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{paginatorReportVNPT004.pageSize *
                                                            paginatorReportVNPT004.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doanh
                                                            nghiệp</th>
                                                        <td mat-cell *matCellDef="let element"> <a
                                                                title="{{element.Name}}" class="link-primary"
                                                                target="_blank"
                                                                href="{{URLSub}}/{{element.ID}}"><b>{{element.Name}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="HuyenName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Huyện</th>
                                                        <td mat-cell *matCellDef="let element"> {{element.HuyenName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Năm
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu01">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>01
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu01 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu02">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>02
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu02 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu03">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>03
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu03 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu04">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>04
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu04 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu05">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>05
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu05 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu06">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>06
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu06 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu07">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>07
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu07 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu08">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>08
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu08 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu09">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>09
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu09 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu10">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>10
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu10 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu11">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>11
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu11 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu12">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>12
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu12 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.displayColumnsReportVNPT003">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.displayColumnsReportVNPT003;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginatorReportVNPT004="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="4" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <select class="form-select" name="nhanVienID" [(ngModel)]="nhanVienID">
                                            <option *ngFor="let item of NhanVienService.list;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="year" [(ngModel)]="year">
                                            <option *ngFor="let item of DownloadService.listYear;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <input [(ngModel)]="searchString" placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="onSearchReportVNPT005()">
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="onSearchReportVNPT005()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-success" title="Download excel"
                                            (click)="onDownloadExcelFileReportVNPT005()"><i
                                                class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp;
                                        <span name="DoanhThu"
                                            style="font-weight: bold; color: limegreen; text-align: right; font-size: 20px;">
                                            {{doanhThuPhongBan | number:
                                            '1.0-0'}}
                                        </span>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Nhân viên
                                            <span
                                                *ngIf="ReportService.listReportVNPT005">({{ReportService.listReportVNPT005.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #sortReportVNPT005="matSort"
                                                    [dataSource]="dataSourceReportVNPT005"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="No">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            No.
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{paginatorReportVNPT004.pageSize *
                                                            paginatorReportVNPT004.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doanh
                                                            nghiệp</th>
                                                        <td mat-cell *matCellDef="let element"> <a
                                                                title="{{element.Name}}" class="link-primary"
                                                                target="_blank"
                                                                href="{{URLSub}}/{{element.ID}}"><b>{{element.Name}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="HuyenName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Huyện</th>
                                                        <td mat-cell *matCellDef="let element"> {{element.HuyenName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Năm
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu01">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>01
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu01 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu02">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>02
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu02 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu03">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>03
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu03 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu04">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>04
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu04 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu05">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>05
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu05 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu06">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>06
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu06 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu07">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>07
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu07 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu08">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>08
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu08 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu09">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>09
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu09 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu10">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>10
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu10 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu11">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>11
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu11 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu12">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>12
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;"><b>{{element.DoanhThu12 |
                                                                number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.displayColumnsReportVNPT003">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.displayColumnsReportVNPT003;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginatorReportVNPT005="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>