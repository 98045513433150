<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center" style="min-width: 500px;">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-bank2 fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Mã số thuế không tồn tại</h2>
            <h6 class="m-0 text-dark fw-light">
                Doanh nghiệp
            </h6>
        </div>
    </div>
    <div class="col-lg-4 col-sm-6 col-12">
    </div>    
    <div class="col-lg-3 col-sm-6 col-12">
        <div class="input-group">
            <input class="form-control" type="text" placeholder="Tìm ..." [(ngModel)]="searchString"
                (keyup.enter)="onSearch()">
            <a class="btn btn-success" title="Tìm..." (click)="onSearch()">
                <i class="bi bi-search"></i>
            </a>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a class="btn btn-success" title="In biểu mẫu">
                <i class="bi bi-printer"></i>
            </a>
            <a class="btn btn-success" title="Download excel" (click)="onDownloadExcelFileDoanhNghiep()">
                <i class="bi bi-cloud-download"></i>
            </a>
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4 card-info">
                <div class="card-header">
                    <h5 class="card-title text-white">Danh sách
                        <span *ngIf="DoanhNghiepService.list">({{DoanhNghiepService.list.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table class="table table-striped m-0">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Mã số thuế</th>
                                        <th>KHACHHANG_ID</th>
                                        <th>Doanh nghiệp</th>
                                        <th>Điện thoại</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let element of DoanhNghiepService.listView; index as i">
                                        <td>{{i + 1}}</td>
                                        <td>
                                            {{element.Code}}
                                        </td>
                                        <td>
                                            {{element.KHACHHANG_ID}}
                                        </td>
                                        <td>
                                            <a class="link-primary" target="_blank" title="{{element.Name}}"
                                                href="{{URLSub}}/{{element.ID}}"><b>{{element.Name}}</b></a>
                                        </td>
                                        <td>
                                            {{element.DienThoai}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>
