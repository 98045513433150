import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doanh-nghiep-dich-vu-ca',
  templateUrl: './doanh-nghiep-dich-vu-ca.component.html',
  styleUrls: ['./doanh-nghiep-dich-vu-ca.component.css']
})
export class DoanhNghiepDichVuCAComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
