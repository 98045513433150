<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center" style="min-width: 300px;">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-emoji-sunglasses fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Nhân viên</h2>
            <h6 class="m-0 text-dark fw-light">
                Nhân viên
            </h6>
        </div>
    </div>
    <div class="col-lg-6 col-sm-6 col-12">
    </div>
    <div class="col-lg-3 col-sm-6 col-12">
        <div class="input-group">
            <input class="form-control" type="text" placeholder="Tìm ..." [(ngModel)]="searchString"
                (keyup.enter)="onSearch()">
            <a class="btn btn-success" title="Tìm..." (click)="onSearch()">
                <i class="bi bi-search"></i>
            </a>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a class="btn btn-success" title="Thêm mới" target="_blank" href="{{URLSub}}/0">
                <i class="bi bi-plus-circle"></i>
            </a>
            <!-- <a class="btn btn-success" title="In biểu mẫu">
                <i class="bi bi-printer"></i>
            </a>
            <a class="btn btn-success" title="Download excel">
                <i class="bi bi-cloud-download"></i>
            </a> -->
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4 card-info">
                <div class="card-header">
                    <h5 class="card-title text-white">Danh sách
                        <span *ngIf="NhanVienService.list">({{NhanVienService.list.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table mat-table matSort #sort="matSort" [dataSource]="dataSource"
                                class="table table-striped m-0">
                                <ng-container matColumnDef="No">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        No.
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;">
                                        {{paginator.pageSize *
                                        paginator.pageIndex + i + 1}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ParentID">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Phòng ban
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div *ngFor="let item of PhongBanService.list;">
                                            <div *ngIf="item.ID==element.ParentID">
                                                {{item.Name}}
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhân viên
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="link-primary" target="_blank" title="{{element.Name}}"
                                            href="{{URLSub}}/{{element.ID}}"><b>{{element.Name}}</b></a>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="DienThoai">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.DienThoai}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Email">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.Email}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" name="Active{{element.ID}}"
                                                [(ngModel)]="element.Active">
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-danger" (click)="onDelete(element)" *ngIf="element.ID>0"><i
                                                class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="NhanVienService.displayColumns">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: NhanVienService.displayColumns;">
                                </tr>
                            </table>
                            <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                [showFirstLastButtons]></mat-paginator>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>