<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center" style="min-width: 300px;">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-bar-chart fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">VNPT-CA & SMART-CA</h2>
            <h6 class="m-0 text-dark fw-light">
                CA - Chữ ký số
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <!-- <a class="link-primary" href="{{domainName}}DashboardCA" title="DashboardCA">
                <b>VNPT-CA & SMART-CA</b>
            </a> |
            <a class="link-primary" href="{{domainName}}CAVNPT" title="VNPT-CA">
                <b>VNPT-CA</b>
            </a> |
            <a class="link-primary" href="{{domainName}}CASmart" title="SMART-CA">
                <b>SMART-CA</b>
            </a> |
            <a class="link-primary" href="{{domainName}}DoanhNghiep" title="UserName">
                <b>Khách hàng</b>
            </a> -->
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-10" data-bs-toggle="tab" href="#10" role="tab"
                                    aria-controls="10" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 14px; width: 120px;">Tổng hợp</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-1" data-bs-toggle="tab" href="#1" role="tab"
                                    aria-controls="1" aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 14px; width: 120px;">Phát
                                        triển</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-2" data-bs-toggle="tab" href="#2" role="tab"
                                    aria-controls="2" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 14px; width: 120px;">Gia hạn</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-5" data-bs-toggle="tab" href="#5" role="tab"
                                    aria-controls="5" aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 14px; width: 120px;">Hết hạn</span>
                                </a>
                            </li>
                            <!-- <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-6" data-bs-toggle="tab" href="#6" role="tab"
                                    aria-controls="6" aria-selected="false">
                                    <span class="badge bg-dark" style="font-size: 14px; width: 120px;">Chưa gia
                                        hạn</span>
                                </a>
                            </li> -->
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-7" data-bs-toggle="tab" href="#7" role="tab"
                                    aria-controls="7" aria-selected="false">
                                    <span class="badge bg-primary" style="font-size: 14px; width: 120px;">Kế
                                        hoạch</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-8" data-bs-toggle="tab" href="#8" role="tab"
                                    aria-controls="8" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 14px; width: 120px;">Hồ sơ</span>
                                </a>
                            </li>
                            <!-- <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-3" data-bs-toggle="tab" href="#3" role="tab"
                                    aria-controls="3" aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 14px; width: 120px;">Thay
                                        đổi</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-4" data-bs-toggle="tab" href="#4" role="tab"
                                    aria-controls="4" aria-selected="false">
                                    <span class="badge bg-info" style="font-size: 14px; width: 120px;">Thu hồi</span>
                                </a>
                            </li> -->
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-101" data-bs-toggle="tab" href="#101" role="tab"
                                    aria-controls="101" aria-selected="false">
                                    <span class="badge bg-info" style="font-size: 14px; width: 120px;">Cấp bù</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade" id="101" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-14">
                                        <input [(ngModel)]="searchString" placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="onSearchCACapBu()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="onSearchCACapBu()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-success" title="Download excel"
                                            (click)="onDownloadExcelFileReportCACapBu()"><i
                                                class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="card mb-4 card-info">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">XẾP HẠNG PHÒNG BÁN HÀNG
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table mat-table matSort #sortReportCACapBu101="matSort"
                                                            [dataSource]="dataSourceReportCACapBu101"
                                                            class="table table-striped m-0">
                                                            <ng-container matColumnDef="No">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Xếp hạng
                                                                </th>
                                                                <td mat-cell *matCellDef="let element; index as i;"
                                                                    style="text-align: center; font-size: 18px;">
                                                                    {{paginatorReportCACapBu101.pageSize *
                                                                    paginatorReportCACapBu101.pageIndex + i + 1}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="PhongBanTaoYeuCauName">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Phòng Bán hàng
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="font-size: 18px;">
                                                                    <b>{{element.PhongBanTaoYeuCauName}}</b>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="GiaHan">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Thực hiện
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.GiaHan |
                                                                    number:
                                                                    '1.0-0'}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="GiaHanChiTieu">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Chỉ tiêu
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.GiaHanChiTieu |
                                                                    number:
                                                                    '1.0-0'}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="TyLeHoSo">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Tỷ lệ
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.TyLeHoSo |
                                                                    number:
                                                                    '1.0-0'}} %
                                                                </td>
                                                            </ng-container>
                                                            <tr mat-header-row
                                                                *matHeaderRowDef="ReportService.displayColumnsReportCACapBu101">
                                                            </tr>
                                                            <tr mat-row
                                                                *matRowDef="let row; columns: ReportService.displayColumnsReportCACapBu101;">
                                                            </tr>
                                                        </table>
                                                        <mat-paginator #paginatorReportCACapBu101="matPaginator"
                                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                                            [showFirstLastButtons]></mat-paginator>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="card mb-4 card-success">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">XẾP HẠNG NHÂN VIÊN
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table mat-table matSort #sortReportCACapBu102="matSort"
                                                            [dataSource]="dataSourceReportCACapBu102"
                                                            class="table table-striped m-0">
                                                            <ng-container matColumnDef="No">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Xếp hạng
                                                                </th>
                                                                <td mat-cell *matCellDef="let element; index as i;"
                                                                    style="text-align: center; font-size: 18px;">
                                                                    {{paginatorReportCACapBu102.pageSize *
                                                                    paginatorReportCACapBu102.pageIndex + i + 1}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="NhanVienTaoYeuCauName">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Nhân viên
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="font-size: 18px;">
                                                                    <b>{{element.NhanVienTaoYeuCauName}}</b>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="GiaHan">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Thực hiện
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.GiaHan |
                                                                    number:
                                                                    '1.0-0'}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="GiaHanChiTieu">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Chỉ tiêu
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.GiaHanChiTieu |
                                                                    number:
                                                                    '1.0-0'}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="TyLeHoSo">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Tỷ lệ
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.TyLeHoSo |
                                                                    number:
                                                                    '1.0-0'}} %
                                                                </td>
                                                            </ng-container>
                                                            <tr mat-header-row
                                                                *matHeaderRowDef="ReportService.displayColumnsReportCACapBu102">
                                                            </tr>
                                                            <tr mat-row
                                                                *matRowDef="let row; columns: ReportService.displayColumnsReportCACapBu102;">
                                                            </tr>
                                                        </table>
                                                        <mat-paginator #paginatorReportCACapBu102="matPaginator"
                                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="100"
                                                            [showFirstLastButtons]></mat-paginator>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span
                                                *ngIf="ReportService.listReportCACapBu103">({{ReportService.listReportCACapBu103.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #sortReportCACapBu103="matSort"
                                                    [dataSource]="dataSourceReportCACapBu103"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="DoanhNghiepName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                            class="sticky-col" style="background-color: black;">
                                                            <span style="color: #ffffff;">Doanh nghiệp</span>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element index as i;"
                                                            class="sticky-col">
                                                            <a *ngIf="element.ID>0" class="btn btn-success"
                                                                title="Upload hồ sơ"
                                                                (click)="onAddReportCACapBu(element.ID)">Upload hồ
                                                                sơ</a>&nbsp;&nbsp;&nbsp;
                                                            <a title="{{element.DoanhNghiepName}}" class="link-primary"
                                                                target="_blank"
                                                                href="{{URLSub}}/{{element.DoanhNghiepID}}"><b>{{paginatorReportCACapBu103.pageSize
                                                                    *
                                                                    paginatorReportCACapBu103.pageIndex + i + 1}} |
                                                                    {{element.DoanhNghiepName}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="UserName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>UserName
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.UserName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsCapBu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cấp bù
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsCapBu{{element.ID}}"
                                                                    [(ngModel)]="element.IsCapBu">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsKetLuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hoàn thành
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsKetLuan{{element.ID}}"
                                                                    [(ngModel)]="element.IsKetLuan">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetLuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kết luận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div style="display: flex; white-space: nowrap;">
                                                                <b>{{element.KetLuan}}</b>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsHopDong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hợp đồng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsHopDong{{element.ID}}"
                                                                            [(ngModel)]="element.IsHopDong">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success" *ngIf="element.HopDong"
                                                                        target="_blank" href="{{element.HopDong}}"
                                                                        title="{{element.HoaDon}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsDonXinCapChungThuSo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phiếu đăng
                                                            ký
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsDonXinCapChungThuSo{{element.ID}}"
                                                                            [(ngModel)]="element.IsDonXinCapChungThuSo">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.DonXinCapChungThuSo"
                                                                        target="_blank"
                                                                        href="{{element.DonXinCapChungThuSo}}"
                                                                        title="{{element.DonXinCapChungThuSo}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsCCCD">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>CCCD/HC
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsCCCD{{element.ID}}"
                                                                            [(ngModel)]="element.IsCCCD">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success" *ngIf="element.CCCD"
                                                                        target="_blank" href="{{element.CCCD}}"
                                                                        title="{{element.CCCD}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsGiayPhepKinhDoanh">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Giấy phép
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsGiayPhepKinhDoanh{{element.ID}}"
                                                                            [(ngModel)]="element.IsGiayPhepKinhDoanh">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.GiayPhepKinhDoanh"
                                                                        target="_blank"
                                                                        href="{{element.GiayPhepKinhDoanh}}"
                                                                        title="{{element.GiayPhepKinhDoanh}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsBienBanNghiemThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Biên bản
                                                            NT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsBienBanNghiemThu{{element.ID}}"
                                                                            [(ngModel)]="element.IsBienBanNghiemThu">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.BienBanNghiemThu" target="_blank"
                                                                        href="{{element.BienBanNghiemThu}}"
                                                                        title="{{element.BienBanNghiemThu}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsHoaDon">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hoá đơn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsHoaDon{{element.ID}}"
                                                                            [(ngModel)]="element.IsHoaDon">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success" *ngIf="element.HoaDon"
                                                                        target="_blank" href="{{element.HoaDon}}"
                                                                        title="{{element.HoaDon}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Note}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CodeCA">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số thuế
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.CodeCA}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThuCu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                            cũ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThuCu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHieuLuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hiệu lực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHieuLuc |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHetHan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hết hạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHetHan |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TenGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gói cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.TenGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại gói
                                                            cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.LoaiGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThoiGianGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thời gian
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.ThoiGianGoiCuoc |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Email">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.Email}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienThoai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.DienThoai}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanTaoYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tạo yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanTaoYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NhanVienTaoYeuCauName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhân viên
                                                            AM
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.NhanVienTaoYeuCauName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanDuyetYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Duyệt yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanDuyetYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="PhongBanDuyetYeuCauName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phòng ban
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.PhongBanDuyetYeuCauName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.LoaiYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doanh thu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.DoanhThu |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.displayColumnsReportCACapBu">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.displayColumnsReportCACapBu;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginatorReportCACapBu103="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade show active" id="10" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <select class="form-select" name="year" [(ngModel)]="year">
                                            <option *ngFor="let item of DownloadService.listYear;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <select class="form-select" name="month" [(ngModel)]="month">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of DownloadService.listMonth;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-8 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="onSearchReportCA201()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-success" title="Báo cáo" (click)="onReportCA()"><i
                                                class="bi bi-printer"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-xl-1 col-sm-6 col-12"></div>
                                    <div class="col-xl-2 col-sm-6 col-12">
                                        <div class="card my-2 card-success">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">Doanh thu</h5>
                                            </div>
                                            <div class="card-body">
                                                <h1 class="display-3 text-white m-0 lh-1 text-center">
                                                    {{ReportService.formData.DoanhThuLast |
                                                    number:
                                                    '1.0-0'}}</h1>
                                                <h6 class="text-white m-0" style="text-align: right;">DVT: triệu đồng
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-sm-6 col-12">
                                        <div class="card my-2 card-danger">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">Sản lượng</h5>
                                            </div>
                                            <div class="card-body">
                                                <h1 class="display-3 text-white m-0 lh-1 text-center">
                                                    {{ReportService.formData.SanLuong |
                                                    number:
                                                    '1.0-0'}}</h1>
                                                <h6 class="text-white m-0" style="text-align: right;">Phát triển + Gia
                                                    hạn</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-sm-6 col-12">
                                        <div class="card my-2 card-info">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">Phát triển</h5>
                                            </div>
                                            <div class="card-body">
                                                <h1 class="display-3 text-white m-0 lh-1 text-center">
                                                    {{ReportService.formData.PhatTrien |
                                                    number:
                                                    '1.0-0'}}</h1>
                                                <h6 class="text-white m-0" style="text-align: right;">Chỉ tiêu:
                                                    {{ReportService.formData.PhatTrienChiTieu |
                                                    number:
                                                    '1.0-0'}} - Tỷ lệ: {{ReportService.formData.TyLePhatTrien
                                                    |
                                                    number:
                                                    '1.0-0'}} %</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-sm-6 col-12">
                                        <div class="card my-2 card-warning">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">Gia hạn</h5>
                                            </div>
                                            <div class="card-body">
                                                <h1 class="display-3 text-white m-0 lh-1 text-center">
                                                    {{ReportService.formData.GiaHan |
                                                    number:
                                                    '1.0-0'}}</h1>
                                                <h6 class="text-white m-0" style="text-align: right;">Chỉ tiêu:
                                                    {{ReportService.formData.GiaHanChiTieu |
                                                    number:
                                                    '1.0-0'}} - Tỷ lệ: {{ReportService.formData.TyLeGiaHan
                                                    |
                                                    number:
                                                    '1.0-0'}} %</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-sm-6 col-12">
                                        <div class="card my-2 card-primary">
                                            <div class="card-header">
                                                <h5 class="card-title text-white bg-primary">Hồ sơ</h5>
                                            </div>
                                            <div class="card-body bg-primary">
                                                <h1 class="display-3 text-white m-0 lh-1 text-center">
                                                    {{ReportService.formData.HoSoHoanThanh |
                                                    number:
                                                    '1.0-0'}}/{{ReportService.formData.SanLuong |
                                                    number:
                                                    '1.0-0'}}</h1>
                                                <h6 class="text-white m-0" style="text-align: right;">Tỷ lệ:
                                                    {{ReportService.formData.TyLeHoSo
                                                    |
                                                    number:
                                                    '1.0-0'}} %</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-1 col-sm-6 col-12"></div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <h3 class="text-center" style="text-transform: uppercase;">Phát triển mới</h3>
                                        <canvas style="font-size: 30px;" baseChart
                                            [datasets]="ChartDataReportCA202PhatTrien"
                                            [labels]="ChartLabelsReportCA202PhatTrien"
                                            [chartType]="ChartTypeReportCA202PhatTrien"
                                            [colors]="ChartColorsReportCA202PhatTrien"
                                            [options]="ChartOptionsReportCA202PhatTrien">
                                        </canvas>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <h3 class="text-center" style="text-transform: uppercase;">Gia hạn</h3>
                                        <canvas baseChart [datasets]="ChartDataReportCA202GiaHan"
                                            [labels]="ChartLabelsReportCA202GiaHan"
                                            [chartType]="ChartTypeReportCA202GiaHan"
                                            [colors]="ChartColorsReportCA202GiaHan"
                                            [options]="ChartOptionsReportCA202GiaHan">
                                        </canvas>
                                    </div>
                                </div>
                                <br />
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <h3 class="text-center" style="text-transform: uppercase;">Sản lượng nhóm khách
                                            hàng</h3>
                                        <canvas baseChart [datasets]="ChartDataReportCA205SanLuong"
                                            [labels]="ChartLabelsReportCA205SanLuong"
                                            [chartType]="ChartTypeReportCA205SanLuong"
                                            [colors]="ChartColorsReportCA205SanLuong"
                                            [options]="ChartOptionsReportCA205SanLuong">
                                        </canvas>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <h3 class="text-center" style="text-transform: uppercase;">Doanh thu nhóm khách
                                            hàng</h3>
                                        <canvas baseChart [datasets]="ChartDataReportCA205DoanhThu"
                                            [labels]="ChartLabelsReportCA205DoanhThu"
                                            [chartType]="ChartTypeReportCA205DoanhThu"
                                            [colors]="ChartColorsReportCA205DoanhThu"
                                            [options]="ChartOptionsReportCA205DoanhThu">
                                        </canvas>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="card mb-4 card-info">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">XẾP HẠNG PHÒNG BÁN HÀNG
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table mat-table matSort #sort204="matSort"
                                                            [dataSource]="dataSource204"
                                                            class="table table-striped m-0">
                                                            <ng-container matColumnDef="No">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Xếp hạng
                                                                </th>
                                                                <td mat-cell *matCellDef="let element; index as i;"
                                                                    style="text-align: center; font-size: 18px;">
                                                                    {{paginator204.pageSize *
                                                                    paginator204.pageIndex + i + 1}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="NhanVienTaoYeuCauName">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Phòng Bán hàng
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="font-size: 18px;">
                                                                    <b>{{element.PhongBanTaoYeuCauName}}</b>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="SanLuong">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Thực hiện
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.SanLuong |
                                                                    number:
                                                                    '1.0-0'}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="PhatTrienChiTieu">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Chỉ tiêu
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.PhatTrienChiTieu |
                                                                    number:
                                                                    '1.0-0'}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="DoanhThu">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Doanh thu
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.DoanhThu |
                                                                    number:
                                                                    '1.0-0'}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="TyLePhatTrien">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Tỷ lệ
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.TyLePhatTrien |
                                                                    number:
                                                                    '1.0-0'}} %
                                                                </td>
                                                            </ng-container>
                                                            <tr mat-header-row
                                                                *matHeaderRowDef="ReportService.displayColumnsReportCA204">
                                                            </tr>
                                                            <tr mat-row
                                                                *matRowDef="let row; columns: ReportService.displayColumnsReportCA204;">
                                                            </tr>
                                                        </table>
                                                        <mat-paginator #paginator204="matPaginator"
                                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                                            [showFirstLastButtons]></mat-paginator>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="card mb-4 card-success">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">XẾP HẠNG NHÂN VIÊN
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table mat-table matSort #sort203="matSort"
                                                            [dataSource]="dataSource203"
                                                            class="table table-striped m-0">
                                                            <ng-container matColumnDef="No">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Xếp hạng
                                                                </th>
                                                                <td mat-cell *matCellDef="let element; index as i;"
                                                                    style="text-align: center; font-size: 18px;">
                                                                    {{paginator203.pageSize *
                                                                    paginator203.pageIndex + i + 1}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="NhanVienTaoYeuCauName">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Nhân viên
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="font-size: 18px;">
                                                                    <b>{{element.NhanVienTaoYeuCauName}}</b>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="SanLuong">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Thực hiện
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.SanLuong |
                                                                    number:
                                                                    '1.0-0'}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="PhatTrienChiTieu">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Chỉ tiêu
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.PhatTrienChiTieu |
                                                                    number:
                                                                    '1.0-0'}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="DoanhThu">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Doanh thu
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.DoanhThu |
                                                                    number:
                                                                    '1.0-0'}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="TyLePhatTrien">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Tỷ lệ
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.TyLePhatTrien |
                                                                    number:
                                                                    '1.0-0'}} %
                                                                </td>
                                                            </ng-container>
                                                            <tr mat-header-row
                                                                *matHeaderRowDef="ReportService.displayColumnsReportCA204">
                                                            </tr>
                                                            <tr mat-row
                                                                *matRowDef="let row; columns: ReportService.displayColumnsReportCA204;">
                                                            </tr>
                                                        </table>
                                                        <mat-paginator #paginator203="matPaginator"
                                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="100"
                                                            [showFirstLastButtons]></mat-paginator>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="1" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <select class="form-select" name="huyenID" [(ngModel)]="huyenID">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of HuyenService.list;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="year" [(ngModel)]="year">
                                            <option *ngFor="let item of DownloadService.listYear;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="month" [(ngModel)]="month">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of DownloadService.listMonth;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-14">
                                        <input [(ngModel)]="searchString" placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="onSearchReportCA001()">
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="onSearchReportCA001()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-success" title="Download excel"
                                            (click)="onDownloadExcelFileReportCA001()"><i
                                                class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp;
                                        <!-- <a class="btn btn-success" title="Download excel"
                                            (click)="onDownloadExcelFileReportCA001()"><i
                                                class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp; -->
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <h3 class="text-center" style="text-transform: uppercase;">Sản lượng</h3>
                                        <canvas baseChart [datasets]="ChartDataReportCA305SanLuong"
                                            [labels]="ChartLabelsReportCA305SanLuong"
                                            [chartType]="ChartTypeReportCA305SanLuong"
                                            [colors]="ChartColorsReportCA305SanLuong"
                                            [options]="ChartOptionsReportCA305SanLuong">
                                        </canvas>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <h3 class="text-center" style="text-transform: uppercase;">Doanh thu</h3>
                                        <canvas baseChart [datasets]="ChartDataReportCA305DoanhThu"
                                            [labels]="ChartLabelsReportCA305DoanhThu"
                                            [chartType]="ChartTypeReportCA305DoanhThu"
                                            [colors]="ChartColorsReportCA305DoanhThu"
                                            [options]="ChartOptionsReportCA305DoanhThu">
                                        </canvas>
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Phát triển
                                            <span
                                                *ngIf="ReportService.listReportCA001">({{ReportService.listReportCA001.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #sort1="matSort" [dataSource]="dataSource1"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="DoanhNghiepName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                            class="sticky-col" style="background-color: black;">
                                                            <span style="color: #ffffff;">Doanh nghiệp</span>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element index as i;"
                                                            class="sticky-col">
                                                            <a title="{{element.DoanhNghiepName}}" class="link-primary"
                                                                target="_blank"
                                                                href="{{URLSub}}/{{element.DoanhNghiepID}}"><b>{{paginator1.pageSize
                                                                    *
                                                                    paginator1.pageIndex + i + 1}} |
                                                                    {{element.DoanhNghiepName}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="UserName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>UserName
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.UserName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThuCu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                            cũ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThuCu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHieuLuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hiệu lực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHieuLuc |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHetHan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hết hạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHetHan |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TenGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gói cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.TenGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại gói
                                                            cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.LoaiGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThoiGianGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thời gian
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.ThoiGianGoiCuoc |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Email">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.Email}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienThoai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.DienThoai}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanTaoYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tạo yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanTaoYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NhanVienTaoYeuCauName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhân viên
                                                            AM
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.NhanVienTaoYeuCauName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanDuyetYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Duyệt yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanDuyetYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="PhongBanDuyetYeuCauName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phòng ban
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.PhongBanDuyetYeuCauName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.LoaiYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doanh thu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.DoanhThu |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.displayColumnsReportCA202312">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.displayColumnsReportCA202312;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginator1="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="2" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <select class="form-select" name="huyenID" [(ngModel)]="huyenID">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of HuyenService.list;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="year" [(ngModel)]="year">
                                            <option *ngFor="let item of DownloadService.listYear;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="month" [(ngModel)]="month">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of DownloadService.listMonth;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-14">
                                        <input [(ngModel)]="searchString" placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="onSearchReportCA002()">
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="onSearchReportCA002()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-success" title="Download excel"
                                            (click)="onDownloadExcelFileReportCA002()"><i
                                                class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <h3 class="text-center" style="text-transform: uppercase;">Sản lượng</h3>
                                        <canvas baseChart [datasets]="ChartDataReportCA306SanLuong"
                                            [labels]="ChartLabelsReportCA306SanLuong"
                                            [chartType]="ChartTypeReportCA306SanLuong"
                                            [colors]="ChartColorsReportCA306SanLuong"
                                            [options]="ChartOptionsReportCA306SanLuong">
                                        </canvas>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <h3 class="text-center" style="text-transform: uppercase;">Doanh thu</h3>
                                        <canvas baseChart [datasets]="ChartDataReportCA306DoanhThu"
                                            [labels]="ChartLabelsReportCA306DoanhThu"
                                            [chartType]="ChartTypeReportCA306DoanhThu"
                                            [colors]="ChartColorsReportCA306DoanhThu"
                                            [options]="ChartOptionsReportCA306DoanhThu">
                                        </canvas>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Gia hạn
                                            <span
                                                *ngIf="ReportService.listReportCA002">({{ReportService.listReportCA002.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #sort2="matSort" [dataSource]="dataSource2"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="DoanhNghiepName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                            class="sticky-col" style="background-color: black;">
                                                            <span style="color: #ffffff;">Doanh nghiệp</span>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element index as i;"
                                                            class="sticky-col">
                                                            <a title="{{element.DoanhNghiepName}}" class="link-primary"
                                                                target="_blank"
                                                                href="{{URLSub}}/{{element.DoanhNghiepID}}"><b>{{paginator2.pageSize
                                                                    *
                                                                    paginator2.pageIndex + i + 1}} |
                                                                    {{element.DoanhNghiepName}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="UserName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>UserName
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.UserName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThuCu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                            cũ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThuCu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHieuLuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hiệu lực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHieuLuc |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHetHan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hết hạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHetHan |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TenGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gói cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.TenGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại gói
                                                            cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.LoaiGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThoiGianGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thời gian
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.ThoiGianGoiCuoc |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Email">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.Email}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienThoai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.DienThoai}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanTaoYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tạo yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanTaoYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NhanVienTaoYeuCauName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhân viên
                                                            AM
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.NhanVienTaoYeuCauName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanDuyetYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Duyệt yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanDuyetYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="PhongBanDuyetYeuCauName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phòng ban
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.PhongBanDuyetYeuCauName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.LoaiYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doanh thu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.DoanhThu |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.displayColumnsReportCA202312">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.displayColumnsReportCA202312;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginator2="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="5" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <select class="form-select" name="huyenID" [(ngModel)]="huyenID">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of HuyenService.list;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="year" [(ngModel)]="year">
                                            <option *ngFor="let item of DownloadService.listYear;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="month" [(ngModel)]="month">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of DownloadService.listMonth;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <select class="form-select" name="hetHan" [(ngModel)]="hetHan">
                                            <option [value]="0">
                                                Hết hạn</option>
                                            <option [value]="4">
                                                Hết hạn loại trừ</option>
                                            <option [value]="1">
                                                Chưa gia hạn</option>
                                            <option [value]="2">
                                                Gia hạn</option>
                                            <option [value]="3">
                                                Phát triển mới trên khách hàng cũ</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-14">
                                        <input [(ngModel)]="searchString" placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="onSearchReportCA005()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="onSearchReportCA005()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-success" title="Download excel"
                                            (click)="onDownloadExcelFileReportCA005()"><i
                                                class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <br />
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <h3 class="text-center" style="text-transform: uppercase;">Sản lượng</h3>
                                        <canvas baseChart [datasets]="ChartDataReportCA307SanLuong"
                                            [labels]="ChartLabelsReportCA307SanLuong"
                                            [chartType]="ChartTypeReportCA307SanLuong"
                                            [colors]="ChartColorsReportCA307SanLuong"
                                            [options]="ChartOptionsReportCA307SanLuong">
                                        </canvas>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <h3 class="text-center" style="text-transform: uppercase;">Doanh thu</h3>
                                        <canvas baseChart [datasets]="ChartDataReportCA307DoanhThu"
                                            [labels]="ChartLabelsReportCA307DoanhThu"
                                            [chartType]="ChartTypeReportCA307DoanhThu"
                                            [colors]="ChartColorsReportCA307DoanhThu"
                                            [options]="ChartOptionsReportCA307DoanhThu">
                                        </canvas>
                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span
                                                *ngIf="ReportService.listReportCA005">({{ReportService.listReportCA005.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #sort5="matSort" [dataSource]="dataSource5"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="DoanhNghiepName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                            class="sticky-col" style="background-color: black;">
                                                            <span style="color: #ffffff;">Doanh nghiệp</span>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element index as i;"
                                                            class="sticky-col">
                                                            <a class="btn btn-success" title="Upload hồ sơ"
                                                                (click)="onAddReportCA005(element.ID)">Upload hồ
                                                                sơ</a>&nbsp;&nbsp;&nbsp;
                                                            <a title="{{element.DoanhNghiepName}}" class="link-primary"
                                                                target="_blank"
                                                                href="{{URLSub}}/{{element.DoanhNghiepID}}"><b>{{paginator5.pageSize
                                                                    *
                                                                    paginator5.pageIndex + i + 1}} |
                                                                    {{element.DoanhNghiepName}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="UserName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>UserName
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.UserName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsKetLuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hoàn thành
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsKetLuan{{element.ID}}"
                                                                    [(ngModel)]="element.IsKetLuan">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetLuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kết luận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div style="display: flex; white-space: nowrap;">
                                                                <b>{{element.KetLuan}}</b>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsHopDong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hợp đồng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsHopDong{{element.ID}}"
                                                                            [(ngModel)]="element.IsHopDong">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success" *ngIf="element.HopDong"
                                                                        target="_blank" href="{{element.HopDong}}"
                                                                        title="{{element.HoaDon}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsDonXinCapChungThuSo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phiếu đăng
                                                            ký
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsDonXinCapChungThuSo{{element.ID}}"
                                                                            [(ngModel)]="element.IsDonXinCapChungThuSo">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.DonXinCapChungThuSo"
                                                                        target="_blank"
                                                                        href="{{element.DonXinCapChungThuSo}}"
                                                                        title="{{element.DonXinCapChungThuSo}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsCCCD">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>CCCD/HC
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsCCCD{{element.ID}}"
                                                                            [(ngModel)]="element.IsCCCD">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success" *ngIf="element.CCCD"
                                                                        target="_blank" href="{{element.CCCD}}"
                                                                        title="{{element.CCCD}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsGiayPhepKinhDoanh">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Giấy phép
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsGiayPhepKinhDoanh{{element.ID}}"
                                                                            [(ngModel)]="element.IsGiayPhepKinhDoanh">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.GiayPhepKinhDoanh"
                                                                        target="_blank"
                                                                        href="{{element.GiayPhepKinhDoanh}}"
                                                                        title="{{element.GiayPhepKinhDoanh}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsBienBanNghiemThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Biên bản
                                                            NT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsBienBanNghiemThu{{element.ID}}"
                                                                            [(ngModel)]="element.IsBienBanNghiemThu">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.BienBanNghiemThu" target="_blank"
                                                                        href="{{element.BienBanNghiemThu}}"
                                                                        title="{{element.BienBanNghiemThu}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsHoaDon">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hoá đơn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsHoaDon{{element.ID}}"
                                                                            [(ngModel)]="element.IsHoaDon">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success" *ngIf="element.HoaDon"
                                                                        target="_blank" href="{{element.HoaDon}}"
                                                                        title="{{element.HoaDon}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Note}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CodeCA">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số thuế
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.CodeCA}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThuCu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                            cũ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThuCu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHieuLuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hiệu lực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHieuLuc |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHetHan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hết hạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHetHan |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TenGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gói cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.TenGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại gói
                                                            cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.LoaiGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThoiGianGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thời gian
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.ThoiGianGoiCuoc |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Email">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.Email}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienThoai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.DienThoai}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanTaoYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tạo yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanTaoYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NhanVienTaoYeuCauName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhân viên
                                                            AM
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.NhanVienTaoYeuCauName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanDuyetYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Duyệt yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanDuyetYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="PhongBanDuyetYeuCauName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phòng ban
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.PhongBanDuyetYeuCauName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.LoaiYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doanh thu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.DoanhThu |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.displayColumnsReportCA002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.displayColumnsReportCA002;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginator5="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="6" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <select class="form-select" name="huyenID" [(ngModel)]="huyenID">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of HuyenService.list;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-5 col-sm-12 col-14">
                                        <input [(ngModel)]="searchString" placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="onSearchReportCA006()">
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="onSearchReportCA006()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <!-- <a class="btn btn-success" title="Download excel"
                                            (click)="onDownloadExcelFileReportCA006()"><i
                                                class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp; -->
                                    </div>
                                </div>
                                <div class="card mb-4 card-dark">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Chưa gia hạn
                                            <span
                                                *ngIf="ReportService.listReportCA006">({{ReportService.listReportCA006.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #sort6="matSort" [dataSource]="dataSource6"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="DoanhNghiepName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                            class="sticky-col" style="background-color: black;">
                                                            <span style="color: #ffffff;">Doanh nghiệp</span>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element index as i;"
                                                            class="sticky-col">
                                                            <a class="btn btn-success" title="Upload hồ sơ"
                                                                (click)="onAddReportCA006(element.ID)">Upload hồ
                                                                sơ</a>&nbsp;&nbsp;&nbsp;
                                                            <a title="{{element.DoanhNghiepName}}" class="link-primary"
                                                                target="_blank"
                                                                href="{{URLSub}}/{{element.DoanhNghiepID}}"><b>{{paginator6.pageSize
                                                                    *
                                                                    paginator6.pageIndex + i + 1}} |
                                                                    {{element.DoanhNghiepName}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="UserName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>UserName
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.UserName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsKetLuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hoàn thành
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsKetLuan{{element.ID}}"
                                                                    [(ngModel)]="element.IsKetLuan">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetLuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kết luận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div style="display: flex; white-space: nowrap;">
                                                                <b>{{element.KetLuan}}</b>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsHopDong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hợp đồng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsHopDong{{element.ID}}"
                                                                            [(ngModel)]="element.IsHopDong">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success" *ngIf="element.HopDong"
                                                                        target="_blank" href="{{element.HopDong}}"
                                                                        title="{{element.HoaDon}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsDonXinCapChungThuSo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phiếu đăng
                                                            ký
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsDonXinCapChungThuSo{{element.ID}}"
                                                                            [(ngModel)]="element.IsDonXinCapChungThuSo">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.DonXinCapChungThuSo"
                                                                        target="_blank"
                                                                        href="{{element.DonXinCapChungThuSo}}"
                                                                        title="{{element.DonXinCapChungThuSo}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsCCCD">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>CCCD/HC
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsCCCD{{element.ID}}"
                                                                            [(ngModel)]="element.IsCCCD">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success" *ngIf="element.CCCD"
                                                                        target="_blank" href="{{element.CCCD}}"
                                                                        title="{{element.CCCD}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsGiayPhepKinhDoanh">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Giấy phép
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsGiayPhepKinhDoanh{{element.ID}}"
                                                                            [(ngModel)]="element.IsGiayPhepKinhDoanh">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.GiayPhepKinhDoanh"
                                                                        target="_blank"
                                                                        href="{{element.GiayPhepKinhDoanh}}"
                                                                        title="{{element.GiayPhepKinhDoanh}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsBienBanNghiemThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Biên bản
                                                            NT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsBienBanNghiemThu{{element.ID}}"
                                                                            [(ngModel)]="element.IsBienBanNghiemThu">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.BienBanNghiemThu" target="_blank"
                                                                        href="{{element.BienBanNghiemThu}}"
                                                                        title="{{element.BienBanNghiemThu}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsHoaDon">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hoá đơn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsHoaDon{{element.ID}}"
                                                                            [(ngModel)]="element.IsHoaDon">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success" *ngIf="element.HoaDon"
                                                                        target="_blank" href="{{element.HoaDon}}"
                                                                        title="{{element.HoaDon}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Note}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CodeCA">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số thuế
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.CodeCA}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThuCu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                            cũ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThuCu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHieuLuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hiệu lực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHieuLuc |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHetHan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hết hạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHetHan |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TenGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gói cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.TenGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại gói
                                                            cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.LoaiGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThoiGianGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thời gian
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.ThoiGianGoiCuoc |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Email">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.Email}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienThoai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.DienThoai}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanTaoYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tạo yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanTaoYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NhanVienTaoYeuCauName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhân viên
                                                            AM
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.NhanVienTaoYeuCauName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanDuyetYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Duyệt yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanDuyetYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="PhongBanDuyetYeuCauName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phòng ban
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.PhongBanDuyetYeuCauName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.LoaiYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doanh thu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.DoanhThu |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.displayColumnsReportCA002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.displayColumnsReportCA002;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginator6="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="7" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <select class="form-select" name="huyenID" [(ngModel)]="huyenID">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of HuyenService.list;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-5 col-sm-12 col-14">
                                        <input [(ngModel)]="searchString" placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="onSearchReportCA007()">
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="onSearchReportCA007()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <!-- <a class="btn btn-success" title="Download excel"
                                            (click)="onDownloadExcelFileReportCA007()"><i
                                                class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp; -->
                                    </div>
                                </div>
                                <div class="card mb-4 card-primary">
                                    <div class="card-header">
                                        <h5 class="card-title text-white bg-primary">Chưa sử dụng
                                            <span
                                                *ngIf="ReportService.listReportCA007">({{ReportService.listReportCA007.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body bg-primary">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #sort7="matSort" [dataSource]="dataSource7"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="No">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            No.
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{paginator7.pageSize *
                                                            paginator7.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="HuyenName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Huyện</th>
                                                        <td mat-cell *matCellDef="let element"> {{element.HuyenName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhNghiepName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doanh
                                                            nghiệp</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a title="{{element.DoanhNghiepName}}" class="link-primary"
                                                                target="_blank"
                                                                href="{{URLSub}}/{{element.DoanhNghiepID}}"><b>{{element.DoanhNghiepName}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.displayColumnsReportCA006">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.displayColumnsReportCA006;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginator7="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="8" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <select class="form-select" name="huyenID" [(ngModel)]="huyenID">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of HuyenService.list;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="year" [(ngModel)]="year">
                                            <option *ngFor="let item of DownloadService.listYear;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="month" [(ngModel)]="month">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of DownloadService.listMonth;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" name="isKetLuan"
                                                [(ngModel)]="isKetLuan">
                                            <label class="form-check-label" for="isKetLuan">Hoàn thành</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-14">
                                        <input [(ngModel)]="searchString" placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="onSearchReportCA008()">
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="onSearchReportCA008()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-success" title="Download excel"
                                            (click)="onDownloadExcelFileReportCA008()"><i
                                                class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a *ngIf="ReportService.NhanVienIDLogin==1" class="btn btn-success"
                                            title="Gửi mail" (click)="DoanhNghiepDichVuCAThieuHoSo()"><i
                                                class="bi bi-envelope"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="card mb-4 card-info">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">PHÒNG BÁN HÀNG
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table mat-table matSort #Sort207="matSort"
                                                            [dataSource]="DataSource207"
                                                            class="table table-striped m-0">
                                                            <ng-container matColumnDef="No">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    STT
                                                                </th>
                                                                <td mat-cell *matCellDef="let element; index as i;"
                                                                    style="text-align: center; font-size: 18px;">
                                                                    {{Paginator207.pageSize *
                                                                    Paginator207.pageIndex + i + 1}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="PhongBanTaoYeuCauName">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    PHÒNG BÁN HÀNG
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="font-size: 18px;">
                                                                    <b>{{element.PhongBanTaoYeuCauName}}</b>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="HoSo">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Hồ sơ
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.HoSo |
                                                                    number:
                                                                    '1.0-0'}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="HoSoHoanThanh">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Hoàn thành
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.HoSoHoanThanh |
                                                                    number:
                                                                    '1.0-0'}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="HoSoChuaHoanThanh">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Chưa Hoàn thành
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.HoSoChuaHoanThanh |
                                                                    number:
                                                                    '1.0-0'}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="TyLeHoanThanh">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Tỷ lệ
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.HoSoHoanThanh/element.HoSo * 100 |
                                                                    number:
                                                                    '1.0-0'}} %
                                                                </td>
                                                            </ng-container>
                                                            <tr mat-header-row
                                                                *matHeaderRowDef="ReportService.displayColumnsReportCA206">
                                                            </tr>
                                                            <tr mat-row
                                                                *matRowDef="let row; columns: ReportService.displayColumnsReportCA206;">
                                                            </tr>
                                                        </table>
                                                        <mat-paginator #Paginator207="matPaginator"
                                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                                            [showFirstLastButtons]></mat-paginator>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="card mb-4 card-success">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">NHÂN VIÊN
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table mat-table matSort #Sort206="matSort"
                                                            [dataSource]="DataSource206"
                                                            class="table table-striped m-0">
                                                            <ng-container matColumnDef="No">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    STT
                                                                </th>
                                                                <td mat-cell *matCellDef="let element; index as i;"
                                                                    style="text-align: center; font-size: 18px;">
                                                                    {{Paginator206.pageSize *
                                                                    Paginator206.pageIndex + i + 1}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="PhongBanTaoYeuCauName">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    NHÂN VIÊN
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="font-size: 18px;">
                                                                    <b>{{element.NhanVienTaoYeuCauName}}</b>
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="HoSo">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Hồ sơ
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.HoSo |
                                                                    number:
                                                                    '1.0-0'}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="HoSoHoanThanh">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Hoàn thành
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.HoSoHoanThanh |
                                                                    number:
                                                                    '1.0-0'}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="HoSoChuaHoanThanh">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Chưa Hoàn thành
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.HoSoChuaHoanThanh |
                                                                    number:
                                                                    '1.0-0'}}
                                                                </td>
                                                            </ng-container>
                                                            <ng-container matColumnDef="TyLeHoanThanh">
                                                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                                    Tỷ lệ
                                                                </th>
                                                                <td mat-cell *matCellDef="let element"
                                                                    style="text-align: right; font-size: 18px;">
                                                                    {{element.HoSoHoanThanh/element.HoSo * 100 |
                                                                    number:
                                                                    '1.0-0'}} %
                                                                </td>
                                                            </ng-container>
                                                            <tr mat-header-row
                                                                *matHeaderRowDef="ReportService.displayColumnsReportCA206">
                                                            </tr>
                                                            <tr mat-row
                                                                *matRowDef="let row; columns: ReportService.displayColumnsReportCA206;">
                                                            </tr>
                                                        </table>
                                                        <mat-paginator #Paginator206="matPaginator"
                                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                                            [showFirstLastButtons]></mat-paginator>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <h3 class="text-center" style="text-transform: uppercase;">Tỷ lệ hoàn thành và
                                            Chưa hoàn thành</h3>
                                        <canvas baseChart [datasets]="ChartDataReportCA308SanLuong"
                                            [labels]="ChartLabelsReportCA308SanLuong"
                                            [chartType]="ChartTypeReportCA308SanLuong"
                                            [colors]="ChartColorsReportCA308SanLuong"
                                            [options]="ChartOptionsReportCA308SanLuong">
                                        </canvas>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <canvas baseChart [datasets]="ChartDataReportCA207"
                                            [labels]="ChartLabelsReportCA207" [chartType]="ChartTypeReportCA207"
                                            [colors]="ChartColorsReportCA207" [options]="ChartOptionsReportCA207">
                                        </canvas>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <canvas baseChart [datasets]="ChartDataReportCA206"
                                            [labels]="ChartLabelsReportCA206" [chartType]="ChartTypeReportCA206"
                                            [colors]="ChartColorsReportCA206" [options]="ChartOptionsReportCA206">
                                        </canvas>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Hồ sơ
                                            <span
                                                *ngIf="ReportService.listReportCA008">({{ReportService.listReportCA008.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #sort8="matSort" [dataSource]="dataSource8"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="DoanhNghiepName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                            class="sticky-col" style="background-color: black;">
                                                            <span style="color: #ffffff;">Doanh nghiệp</span>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element index as i;"
                                                            class="sticky-col">
                                                            <a class="btn btn-success" title="Upload hồ sơ"
                                                                (click)="onAddReportCA008(element.ID)">Upload hồ
                                                                sơ</a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger" title="Xoá chứng thư số"
                                                                (click)="DoanhNghiepDichVuCADelete(element.ID)"><i
                                                                    class="bi bi-trash"></i></a>&nbsp;&nbsp;&nbsp;
                                                            <a title="{{element.DoanhNghiepName}}" class="link-primary"
                                                                target="_blank"
                                                                href="{{URLSub}}/{{element.DoanhNghiepID}}"><b>{{paginator8.pageSize
                                                                    *
                                                                    paginator8.pageIndex + i + 1}} |
                                                                    {{element.DoanhNghiepName}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LastUpdatedDate">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cập nhật
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.LastUpdatedDate |
                                                            date:'dd/MM/yyyy HH:mm:ss'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="UserName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>UserName
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.UserName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsKetLuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hoàn thành
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsKetLuan{{element.ID}}"
                                                                    [(ngModel)]="element.IsKetLuan">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetLuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kết luận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div style="display: flex; white-space: nowrap;">
                                                                <b>{{element.KetLuan}}</b>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsHopDong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hợp đồng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsHopDong{{element.ID}}"
                                                                            [(ngModel)]="element.IsHopDong">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.HopDong && element.HopDong.length>10"
                                                                        target="_blank" href="{{element.HopDong}}"
                                                                        title="{{element.HoaDon}}"><i
                                                                            class="bi bi bi-eye-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsDonXinCapChungThuSo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phiếu đăng
                                                            ký
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsDonXinCapChungThuSo{{element.ID}}"
                                                                            [(ngModel)]="element.IsDonXinCapChungThuSo">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.DonXinCapChungThuSo && element.DonXinCapChungThuSo.length>10"
                                                                        target="_blank"
                                                                        href="{{element.DonXinCapChungThuSo}}"
                                                                        title="{{element.DonXinCapChungThuSo}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsCCCD">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>CCCD/HC
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsCCCD{{element.ID}}"
                                                                            [(ngModel)]="element.IsCCCD">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.CCCD && element.CCCD.length>10"
                                                                        target="_blank" href="{{element.CCCD}}"
                                                                        title="{{element.CCCD}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsGiayPhepKinhDoanh">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Giấy phép
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsGiayPhepKinhDoanh{{element.ID}}"
                                                                            [(ngModel)]="element.IsGiayPhepKinhDoanh">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.GiayPhepKinhDoanh && element.GiayPhepKinhDoanh.length>10"
                                                                        target="_blank"
                                                                        href="{{element.GiayPhepKinhDoanh}}"
                                                                        title="{{element.GiayPhepKinhDoanh}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsBienBanNghiemThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Biên bản
                                                            NT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsBienBanNghiemThu{{element.ID}}"
                                                                            [(ngModel)]="element.IsBienBanNghiemThu">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.BienBanNghiemThu && element.BienBanNghiemThu.length>10"
                                                                        target="_blank"
                                                                        href="{{element.BienBanNghiemThu}}"
                                                                        title="{{element.BienBanNghiemThu}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsHoaDon">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hoá đơn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsHoaDon{{element.ID}}"
                                                                            [(ngModel)]="element.IsHoaDon">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.HoaDon && element.HoaDon.length>10"
                                                                        target="_blank" href="{{element.HoaDon}}"
                                                                        title="{{element.HoaDon}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Note}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CodeCA">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số thuế
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.CodeCA}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThuCu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                            cũ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThuCu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHieuLuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hiệu lực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHieuLuc |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHetHan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hết hạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHetHan |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TenGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gói cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.TenGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại gói
                                                            cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.LoaiGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThoiGianGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thời gian
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.ThoiGianGoiCuoc |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Email">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.Email}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienThoai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.DienThoai}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanTaoYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tạo yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanTaoYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NhanVienTaoYeuCauName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhân viên
                                                            AM
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.NhanVienTaoYeuCauName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanDuyetYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Duyệt yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanDuyetYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="PhongBanDuyetYeuCauName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phòng ban
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.PhongBanDuyetYeuCauName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.LoaiYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doanh thu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.DoanhThu |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.displayColumnsReportCA002001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.displayColumnsReportCA002001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginator8="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="3" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <select class="form-select" name="huyenID" [(ngModel)]="huyenID">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of HuyenService.list;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="year" [(ngModel)]="year">
                                            <option *ngFor="let item of DownloadService.listYear;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="month" [(ngModel)]="month">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of DownloadService.listMonth;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-14">
                                        <input [(ngModel)]="searchString" placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="onSearchReportCA003()">
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="onSearchReportCA003()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <!-- <a class="btn btn-success" title="Download excel"
                                            (click)="onDownloadExcelFileReportCA003()"><i
                                                class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp; -->
                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Thay đổi thông tin
                                            <span
                                                *ngIf="ReportService.listReportCA003">({{ReportService.listReportCA003.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #sort3="matSort" [dataSource]="dataSource3"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="DoanhNghiepName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                            class="sticky-col" style="background-color: black;">
                                                            <span style="color: #ffffff;">Doanh nghiệp</span>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element index as i;"
                                                            class="sticky-col">
                                                            <a class="btn btn-success" title="Upload hồ sơ"
                                                                (click)="onAddReportCA003(element.ID)">Upload hồ
                                                                sơ</a>&nbsp;&nbsp;&nbsp;
                                                            <a title="{{element.DoanhNghiepName}}" class="link-primary"
                                                                target="_blank"
                                                                href="{{URLSub}}/{{element.DoanhNghiepID}}"><b>{{paginator3.pageSize
                                                                    *
                                                                    paginator3.pageIndex + i + 1}} |
                                                                    {{element.DoanhNghiepName}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="UserName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>UserName
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.UserName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsKetLuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hoàn thành
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsKetLuan{{element.ID}}"
                                                                    [(ngModel)]="element.IsKetLuan">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetLuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kết luận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div style="display: flex; white-space: nowrap;">
                                                                <b>{{element.KetLuan}}</b>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsHopDong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hợp đồng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsHopDong{{element.ID}}"
                                                                            [(ngModel)]="element.IsHopDong">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success" *ngIf="element.HopDong"
                                                                        target="_blank" href="{{element.HopDong}}"
                                                                        title="{{element.HoaDon}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsDonXinCapChungThuSo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phiếu đăng
                                                            ký
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsDonXinCapChungThuSo{{element.ID}}"
                                                                            [(ngModel)]="element.IsDonXinCapChungThuSo">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.DonXinCapChungThuSo"
                                                                        target="_blank"
                                                                        href="{{element.DonXinCapChungThuSo}}"
                                                                        title="{{element.DonXinCapChungThuSo}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsCCCD">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>CCCD/HC
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsCCCD{{element.ID}}"
                                                                            [(ngModel)]="element.IsCCCD">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success" *ngIf="element.CCCD"
                                                                        target="_blank" href="{{element.CCCD}}"
                                                                        title="{{element.CCCD}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsGiayPhepKinhDoanh">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Giấy phép
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsGiayPhepKinhDoanh{{element.ID}}"
                                                                            [(ngModel)]="element.IsGiayPhepKinhDoanh">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.GiayPhepKinhDoanh"
                                                                        target="_blank"
                                                                        href="{{element.GiayPhepKinhDoanh}}"
                                                                        title="{{element.GiayPhepKinhDoanh}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsBienBanNghiemThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Biên bản
                                                            NT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsBienBanNghiemThu{{element.ID}}"
                                                                            [(ngModel)]="element.IsBienBanNghiemThu">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.BienBanNghiemThu" target="_blank"
                                                                        href="{{element.BienBanNghiemThu}}"
                                                                        title="{{element.BienBanNghiemThu}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsHoaDon">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hoá đơn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsHoaDon{{element.ID}}"
                                                                            [(ngModel)]="element.IsHoaDon">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success" *ngIf="element.HoaDon"
                                                                        target="_blank" href="{{element.HoaDon}}"
                                                                        title="{{element.HoaDon}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Note}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CodeCA">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số thuế
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.CodeCA}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThuCu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                            cũ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThuCu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHieuLuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hiệu lực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHieuLuc |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHetHan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hết hạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHetHan |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TenGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gói cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.TenGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại gói
                                                            cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.LoaiGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThoiGianGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thời gian
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.ThoiGianGoiCuoc |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Email">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.Email}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienThoai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.DienThoai}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanTaoYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tạo yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanTaoYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NhanVienTaoYeuCauName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhân viên
                                                            AM
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.NhanVienTaoYeuCauName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanDuyetYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Duyệt yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanDuyetYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="PhongBanDuyetYeuCauName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phòng ban
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.PhongBanDuyetYeuCauName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.LoaiYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doanh thu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.DoanhThu |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.displayColumnsReportCA002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.displayColumnsReportCA002;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginator3="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="4" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <select class="form-select" name="huyenID" [(ngModel)]="huyenID">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of HuyenService.list;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="year" [(ngModel)]="year">
                                            <option *ngFor="let item of DownloadService.listYear;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="month" [(ngModel)]="month">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of DownloadService.listMonth;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-14">
                                        <input [(ngModel)]="searchString" placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="onSearchReportCA004()">
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="onSearchReportCA004()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <!-- <a class="btn btn-success" title="Download excel"
                                            (click)="onDownloadExcelFileReportCA004()"><i
                                                class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp; -->
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Thu hồi
                                            <span
                                                *ngIf="ReportService.listReportCA004">({{ReportService.listReportCA004.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #sort4="matSort" [dataSource]="dataSource4"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="DoanhNghiepName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                            class="sticky-col" style="background-color: black;">
                                                            <span style="color: #ffffff;">Doanh nghiệp</span>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element index as i;"
                                                            class="sticky-col">
                                                            <a class="btn btn-success" title="Upload hồ sơ"
                                                                (click)="onAddReportCA004(element.ID)">Upload hồ
                                                                sơ</a>&nbsp;&nbsp;&nbsp;
                                                            <a title="{{element.DoanhNghiepName}}" class="link-primary"
                                                                target="_blank"
                                                                href="{{URLSub}}/{{element.DoanhNghiepID}}"><b>{{paginator4.pageSize
                                                                    *
                                                                    paginator4.pageIndex + i + 1}} |
                                                                    {{element.DoanhNghiepName}}</b></a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="UserName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>UserName
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.UserName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsKetLuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hoàn thành
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsKetLuan{{element.ID}}"
                                                                    [(ngModel)]="element.IsKetLuan">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetLuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kết luận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div style="display: flex; white-space: nowrap;">
                                                                <b>{{element.KetLuan}}</b>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsHopDong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hợp đồng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsHopDong{{element.ID}}"
                                                                            [(ngModel)]="element.IsHopDong">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success" *ngIf="element.HopDong"
                                                                        target="_blank" href="{{element.HopDong}}"
                                                                        title="{{element.HoaDon}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsDonXinCapChungThuSo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phiếu đăng
                                                            ký
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsDonXinCapChungThuSo{{element.ID}}"
                                                                            [(ngModel)]="element.IsDonXinCapChungThuSo">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.DonXinCapChungThuSo"
                                                                        target="_blank"
                                                                        href="{{element.DonXinCapChungThuSo}}"
                                                                        title="{{element.DonXinCapChungThuSo}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsCCCD">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>CCCD/HC
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsCCCD{{element.ID}}"
                                                                            [(ngModel)]="element.IsCCCD">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success" *ngIf="element.CCCD"
                                                                        target="_blank" href="{{element.CCCD}}"
                                                                        title="{{element.CCCD}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsGiayPhepKinhDoanh">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Giấy phép
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsGiayPhepKinhDoanh{{element.ID}}"
                                                                            [(ngModel)]="element.IsGiayPhepKinhDoanh">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.GiayPhepKinhDoanh"
                                                                        target="_blank"
                                                                        href="{{element.GiayPhepKinhDoanh}}"
                                                                        title="{{element.GiayPhepKinhDoanh}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsBienBanNghiemThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Biên bản
                                                            NT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsBienBanNghiemThu{{element.ID}}"
                                                                            [(ngModel)]="element.IsBienBanNghiemThu">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.BienBanNghiemThu" target="_blank"
                                                                        href="{{element.BienBanNghiemThu}}"
                                                                        title="{{element.BienBanNghiemThu}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsHoaDon">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hoá đơn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsHoaDon{{element.ID}}"
                                                                            [(ngModel)]="element.IsHoaDon">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success" *ngIf="element.HoaDon"
                                                                        target="_blank" href="{{element.HoaDon}}"
                                                                        title="{{element.HoaDon}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Note}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CodeCA">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số thuế
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"> {{element.CodeCA}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThuCu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                            cũ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThuCu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHieuLuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hiệu lực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHieuLuc |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHetHan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hết hạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHetHan |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TenGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gói cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.TenGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại gói
                                                            cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.LoaiGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThoiGianGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thời gian
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.ThoiGianGoiCuoc |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Email">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.Email}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienThoai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.DienThoai}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanTaoYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tạo yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanTaoYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NhanVienTaoYeuCauName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nhân viên
                                                            AM
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.NhanVienTaoYeuCauName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanDuyetYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Duyệt yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanDuyetYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="PhongBanDuyetYeuCauName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phòng ban
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.PhongBanDuyetYeuCauName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.LoaiYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doanh thu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.DoanhThu |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.displayColumnsReportCA002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.displayColumnsReportCA002;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginator4="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>