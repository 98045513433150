<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center" style="min-width: 300px;">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-bar-chart fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Tên miền</h2>
            <h6 class="m-0 text-dark fw-light">
                Dashboard
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="1" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <select class="form-select" name="huyenID" [(ngModel)]="huyenID"
                                            (change)="onChangeHuyenID()">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of HuyenService.list;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <select class="form-select" name="xaID" [(ngModel)]="xaID">
                                            <option [value]="0">
                                                Tất cả</option>
                                            <option *ngFor="let item of XaService.list;" [value]="item.ID">
                                                {{item.Name}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <select class="form-select" name="year" [(ngModel)]="year">
                                            <option *ngFor="let item of DownloadService.listYear;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-14">
                                        <input [(ngModel)]="searchString" placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="onSearchReportVNPT003()">
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="onSearchReportVNPT003()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-success" title="Download excel"
                                            (click)="onDownloadExcelFileReportVNPT003()"><i
                                                class="bi bi-cloud-download"></i></a>&nbsp;&nbsp;&nbsp;
                                        <span name="DoanhThu"
                                            style="font-weight: bold; color: limegreen; text-align: right; font-size: 20px;">
                                            {{doanhThuDichVu | number:
                                            '1.0-0'}}
                                        </span>
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Danh sách
                                            <span
                                                *ngIf="ReportService.listReportVNPT003">({{ReportService.listReportVNPT003.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #sort="matSort" [dataSource]="dataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="No">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            No.
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            {{paginator.pageSize *
                                                            paginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doanh
                                                            nghiệp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Name}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="HuyenName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Huyện
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.HuyenName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="XaName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Xã
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.XaName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doanh thu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;;">
                                                            <b>{{element.DoanhThu | number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu01">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>01
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;;">
                                                            <b>{{element.DoanhThu01 | number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu02">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>02
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;;">
                                                            <b>{{element.DoanhThu02 | number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu03">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>03
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;;">
                                                            <b>{{element.DoanhThu03 | number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu04">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>04
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;;">
                                                            <b>{{element.DoanhThu04 | number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu05">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>05
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;;">
                                                            <b>{{element.DoanhThu05 | number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu06">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>06
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;;">
                                                            <b>{{element.DoanhThu06 | number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu07">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>07
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;;">
                                                            <b>{{element.DoanhThu07 | number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu08">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>08
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;;">
                                                            <b>{{element.DoanhThu08 | number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu09">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>09
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;;">
                                                            <b>{{element.DoanhThu09 | number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu10">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>10
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;;">
                                                            <b>{{element.DoanhThu10 | number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu11">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>11
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;;">
                                                            <b>{{element.DoanhThu11 | number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DoanhThu12">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>12
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;;">
                                                            <b>{{element.DoanhThu12 | number:
                                                                '1.0-0'}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ReportService.displayColumnsReportVNPT003">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ReportService.displayColumnsReportVNPT003;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>