<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center" style="min-width: 300px;">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-gear fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Upload</h2>
            <h6 class="m-0 text-dark fw-light">
                Upload
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-1" data-bs-toggle="tab" href="#1" role="tab"
                                    aria-controls="1" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 18px;">Doanh thu tháng</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-2" data-bs-toggle="tab" href="#2" role="tab"
                                    aria-controls="2" aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 18px;">CA</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-6" data-bs-toggle="tab" href="#6" role="tab"
                                    aria-controls="6" aria-selected="false">
                                    <span class="badge bg-primary" style="font-size: 18px;">CA - Cấp bù</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-3" data-bs-toggle="tab" href="#3" role="tab"
                                    aria-controls="3" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 18px;">Doanh nghiệp</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-4" data-bs-toggle="tab" href="#4" role="tab"
                                    aria-controls="4" aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 18px;">Gói cước</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-5" data-bs-toggle="tab" href="#5" role="tab"
                                    aria-controls="5" aria-selected="false">
                                    <span class="badge bg-dark" style="font-size: 18px;">Hồ sơ</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="1" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> <a class="link-primary"
                                                target="_blank" href="{{excelDoanhThuURL}}" title="Tải về">Tải về</a>
                                            file excel mẫu
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 02:</label> Chọn file
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #uploadDoanhThu
                                                (change)="changeDoanhThu($event.target.files)">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 03:</label> Chọn năm
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <select class="form-select" name="year" [(ngModel)]="year">
                                                <option *ngFor="let item of DownloadService.listYear;"
                                                    [value]="item.Display">
                                                    {{item.Display}}</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-6 col-sm-12 col-12">
                                            <label class="form-label">Bước 04:</label> Chọn tháng
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <select class="form-select" name="month" [(ngModel)]="month">
                                                <option *ngFor="let item of DownloadService.listMonth;"
                                                    [value]="item.Display">
                                                    {{item.Display}}</option>
                                            </select>
                                        </div>
                                        <br />
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <button [disabled]="!isDoanhThu" class="btn btn-info" style="width: 100%;"
                                                (click)="onSubmitDoanhThu()">Upload</button>
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-sm-12 col-12">
                                        <div class="card mb-4 card-info">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">Danh sách
                                                    <span
                                                        *ngIf="DoanhNghiepService.list">({{DoanhNghiepService.list.length}}
                                                        items)</span>
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table class="table table-striped m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>Mã số</th>
                                                                    <th>Doanh nghiệp</th>
                                                                    <th>Điện thoại</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let element of DoanhNghiepService.list; index as i">
                                                                    <td>{{i + 1}}</td>
                                                                    <td>{{element.Code}}</td>
                                                                    <td>{{element.Name}}</td>
                                                                    <td>{{element.DienThoai}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="2" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> <a class="link-primary" target="_blank"
                                                href="{{excelCAURL}}" title="Tải về">Tải về</a> file excel mẫu
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 02:</label> Chọn file
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #uploadCA (change)="changeCA($event.target.files)">
                                        </div>
                                        <br />
                                        <button [disabled]="!isCA" class="btn btn-info" (click)="onSubmitCA()" style="width: 100%;">Upload</button>
                                    </div>
                                    <div class="col-lg-8 col-sm-12 col-12">
                                        <div class="card mb-4 card-info">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">Danh sách
                                                    <span
                                                        *ngIf="DoanhNghiepService.list">({{DoanhNghiepService.list.length}}
                                                        items)</span>
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table class="table table-striped m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>Mã số</th>
                                                                    <th>Doanh nghiệp</th>
                                                                    <th>Điện thoại</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let element of DoanhNghiepService.list; index as i">
                                                                    <td>{{i + 1}}</td>
                                                                    <td>{{element.Code}}</td>
                                                                    <td>{{element.Name}}</td>
                                                                    <td>{{element.DienThoai}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="6" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> <a class="link-primary" target="_blank"
                                                href="{{excelCACapBuURL}}" title="Tải về">Tải về</a> file excel mẫu
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 02:</label> Chọn file
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #uploadCACapBu (change)="changeCACapBu($event.target.files)">
                                        </div>
                                        <br />
                                        <button [disabled]="!isCACapBu" class="btn btn-info" (click)="onSubmitCACapBu()" style="width: 100%;">Upload</button>
                                    </div>
                                    <div class="col-lg-8 col-sm-12 col-12">
                                        <div class="card mb-4 card-info">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">Danh sách
                                                    <span
                                                        *ngIf="DoanhNghiepDichVuCAService.list">({{DoanhNghiepDichVuCAService.list.length}}
                                                        items)</span>
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table class="table table-striped m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>Số chứng thư không tồn tại</th>                                                                  
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let element of DoanhNghiepDichVuCAService.list; index as i">
                                                                    <td>{{i + 1}}</td>
                                                                    <td>{{element.SoChungThu}}</td>                                                                   
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="3" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> <a class="link-primary" target="_blank"
                                                href="{{excelDoanhNghiepURL}}" title="Tải về">Tải về</a> file excel mẫu
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 02:</label> Chọn file
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #uploadDoanhNghiep
                                                (change)="changeDoanhNghiep($event.target.files)">
                                        </div>
                                        <br />
                                        <button [disabled]="!isDoanhNghiep" class="btn btn-info" (click)="onSubmit()" style="width: 100%;">Upload</button>
                                    </div>
                                    <div class="col-lg-8 col-sm-12 col-12">
                                        <div class="card mb-4 card-info">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">Danh sách
                                                    <span
                                                        *ngIf="DoanhNghiepService.list">({{DoanhNghiepService.list.length}}
                                                        items)</span>
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table class="table table-striped m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>Mã số</th>
                                                                    <th>Doanh nghiệp</th>
                                                                    <th>Điện thoại</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let element of DoanhNghiepService.list; index as i">
                                                                    <td>{{i + 1}}</td>
                                                                    <td>{{element.Code}}</td>
                                                                    <td>{{element.Name}}</td>
                                                                    <td>{{element.DienThoai}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="4" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> <a class="link-primary" target="_blank"
                                                href="{{excelGoiCuocURL}}" title="Tải về">Tải về</a> file excel mẫu
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 02:</label> Chọn file
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #uploadGoiCuoc
                                                (change)="changeGoiCuoc($event.target.files)">
                                        </div>
                                        <br />
                                        <button [disabled]="!isGoiCuoc" class="btn btn-info" (click)="onSubmitGoiCuoc()" style="width: 100%;">Upload</button>
                                    </div>
                                    <div class="col-lg-8 col-sm-12 col-12">
                                        <div class="card mb-4 card-info">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">Danh sách
                                                    <span
                                                        *ngIf="DoanhNghiepService.list">({{DoanhNghiepService.list.length}}
                                                        items)</span>
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table class="table table-striped m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>Mã số</th>
                                                                    <th>Doanh nghiệp</th>
                                                                    <th>Điện thoại</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let element of DoanhNghiepService.list; index as i">
                                                                    <td>{{i + 1}}</td>
                                                                    <td>{{element.Code}}</td>
                                                                    <td>{{element.Name}}</td>
                                                                    <td>{{element.DienThoai}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="5" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> <a class="link-primary" target="_blank"
                                                href="{{excelCAHoSoURL}}" title="Tải về">Tải về</a> file excel mẫu
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 02:</label> Chọn file
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #uploadCAHoSo
                                                (change)="changeCAHoSo($event.target.files)">
                                        </div>
                                        <br />
                                        <button [disabled]="!isCAHoSo" class="btn btn-info" (click)="onSubmitCAHoSo()" style="width: 100%;">Upload</button>
                                    </div>
                                    <div class="col-lg-8 col-sm-12 col-12">                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>
