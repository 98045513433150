<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center" style="min-width: 300px;">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-emoji-sunglasses fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">{{PhongBanService.formData.Name}}</h2>
            <h6 class="m-0 text-dark fw-light">
                Phòng ban
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-1" data-bs-toggle="tab" href="#1" role="tab"
                                    aria-controls="1" aria-selected="false">
                                    <span class="badge bg-info">Doanh thu</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-2" data-bs-toggle="tab" href="#2" role="tab"
                                    aria-controls="2" aria-selected="false">
                                    <span class="badge bg-danger">Biểu đồ</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-3" data-bs-toggle="tab" href="#3" role="tab"
                                    aria-controls="3" aria-selected="false">
                                    <span class="badge bg-success">Khu vực</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-4" data-bs-toggle="tab" href="#4" role="tab"
                                    aria-controls="4" aria-selected="true">
                                    <span class="badge bg-primary">Thông tin</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade" id="1" role="tabpanel">
                                <h1 class="display-1 text-center text-danger p-5">
                                    1
                                </h1>
                            </div>
                            <div class="tab-pane fade" id="2" role="tabpanel">
                                <h1 class="display-1 text-center text-success p-5">
                                    2
                                </h1>
                            </div>
                            <div class="tab-pane fade" id="3" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-5 col-sm-12 col-12">
                                        <div class="card mb-4 card-info">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">Phân quyền khu vực
                                                    <span
                                                        *ngIf="PhongBanKhuVucService.list">({{PhongBanKhuVucService.list.length}}
                                                        items)</span>
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table class="table table-striped m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>Huyện</th>
                                                                    <th>Xã</th>
                                                                    <th>Phân quyền</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let element of PhongBanKhuVucService.list; index as i">
                                                                    <td>{{i + 1}}</td>
                                                                    <td>
                                                                        <div *ngFor="let item of HuyenService.list;">
                                                                            <div *ngIf="item.ID==element.HuyenID">
                                                                                {{item.Name}}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div *ngFor="let item of XaService.list;">
                                                                            <div *ngIf="item.ID==element.XaID">
                                                                                {{item.Name}}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="form-check form-check-inline">
                                                                            <input class="form-check-input"
                                                                                type="checkbox"
                                                                                (change)="onPhongBanKhuVucActiveChange(element)"
                                                                                name="Active{{element.ID}}"
                                                                                [(ngModel)]="element.Active">
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-sm-12 col-12">
                                        <div class="card mb-4 card-light">
                                            <div class="card-header">
                                                <h5 class="card-title">Phân quyền doanh nghiệp
                                                    <span
                                                        *ngIf="DoanhNghiepService.listSearch">({{DoanhNghiepService.listSearch.length}}
                                                        items)</span>
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="row gx-4">
                                                    <div class="col-lg-10 col-sm-12 col-12">
                                                        <div class="input-group">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tìm ..." [(ngModel)]="searchString"
                                                                (keyup.enter)="onSearch()">
                                                            <a class="btn btn-success" title="Tìm..."
                                                                (click)="onSearch()">
                                                                <i class="bi bi-search"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-2 col-sm-12 col-12">
                                                        <a class="btn btn-info" title="Lưu thay đổi" (click)="onSave()">
                                                            <i class="bi bi-sd-card"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="row gx-4">
                                                    <div class="table-outer">
                                                        <div class="table-responsive">
                                                            <table class="table table-striped m-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>No</th>
                                                                        <th>MST</th>
                                                                        <th>Doanh nghiệp</th>
                                                                        <th>Huyện</th>
                                                                        <th>Xã</th>
                                                                        <th>Phòng ban</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr
                                                                        *ngFor="let element of DoanhNghiepService.listSearch; index as i">
                                                                        <td>{{i + 1}}</td>
                                                                        <td>{{element.Code}}</td>
                                                                        <td><a title="{{element.Name}}" target="_blank"
                                                                                href="{{URLSubDoanhNghiep}}/{{element.ID}}"><b>{{element.Name}}</b></a>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                *ngFor="let item of HuyenService.list;">
                                                                                <div *ngIf="item.ID==element.HuyenID">
                                                                                    {{item.Name}}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div *ngFor="let item of XaService.list;">
                                                                                <div *ngIf="item.ID==element.XaID">
                                                                                    {{item.Name}}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div
                                                                                *ngFor="let item of PhongBanService.list;">
                                                                                <div
                                                                                    *ngIf="item.ID==element.PhongBanID">
                                                                                    {{item.Name}}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade show active" id="4" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-5 col-sm-12 col-12">
                                        <div class="card mb-4 card-light">
                                            <div class="card-header">
                                                <h5 class="card-title">Thông tin phòng ban
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <form autocomplete="off" class="row" #form="ngForm"
                                                    (submit)="onSubmit(form)">
                                                    <div class="row gx-4">
                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                            <a title="Danh sách" class="btn btn-success"
                                                                href="javascript:window.open('','_self').close();"><i
                                                                    class="bi bi-arrow-left-square"></i></a>&nbsp;&nbsp;&nbsp;
                                                            <a title="Thêm mới" href="{{URLSub}}/0"
                                                                class="btn btn-success"><i
                                                                    class="bi bi-plus-square-dotted"></i></a>&nbsp;&nbsp;&nbsp;
                                                            <button [disabled]="!PhongBanService.formData.Name"
                                                                title="Lưu thay đổi" type="submit"
                                                                class="btn btn-info"><i
                                                                    class="bi bi-sd-card"></i></button>
                                                        </div>
                                                    </div>
                                                    <input name="ID" [(ngModel)]="PhongBanService.formData.ID"
                                                        type="hidden">
                                                    <div class="row gx-4">
                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Phòng ban</label>
                                                                <input name="Name"
                                                                    [(ngModel)]="PhongBanService.formData.Name"
                                                                    placeholder="Phòng ban" type="text"
                                                                    class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Code</label>
                                                                <input name="Code"
                                                                    [(ngModel)]="PhongBanService.formData.Code"
                                                                    placeholder="Code" type="text" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Ghi chú</label>
                                                                <input name="Note"
                                                                    [(ngModel)]="PhongBanService.formData.Note"
                                                                    placeholder="Ghi chú" type="text"
                                                                    class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                            <div class="mb-3">
                                                                <label class="form-label">Sắp xếp</label>
                                                                <input name="SortOrder"
                                                                    [(ngModel)]="PhongBanService.formData.SortOrder"
                                                                    placeholder="0" type="number" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                            <div class="form-check form-check-inline mb-3">
                                                                <input type="checkbox" class="form-check-input"
                                                                    name="Active"
                                                                    [(ngModel)]="PhongBanService.formData.Active" />
                                                                <label class="form-check-label" for="Active">Kích
                                                                    hoạt</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-sm-12 col-12">
                                        <div class="card mb-4 card-info">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">Phân quyền nhân viên
                                                    <span
                                                        *ngIf="NhanVienService.list">({{NhanVienService.list.length}}
                                                        items)</span>
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table class="table table-striped m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>Mã Nhân viên</th>
                                                                    <th>Nhân viên</th>
                                                                    <th>Điện thoại</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let element of NhanVienService.list; index as i">
                                                                    <td>{{i + 1}}</td>
                                                                    <td>
                                                                        {{element.Code}}
                                                                    </td>
                                                                    <td>
                                                                        <a class="link-primary" target="_blank" title="{{element.Name}}"
                                                                            href="{{URLSubNhanVien}}/{{element.ID}}"><b>{{element.Name}}</b></a>
                                                                    </td>
                                                                    <td>
                                                                        {{element.DienThoai}}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>