<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center" style="min-width: 300px;">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-bank2 fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">{{DoanhNghiepService.formData.Name}}</h2>
            <h6 class="m-0 text-dark fw-light">
                Khách hàng
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-1" data-bs-toggle="tab" href="#1" role="tab"
                                    aria-controls="1" aria-selected="false">
                                    <span class="badge bg-info" style="font-size: 14px; width: 120px;">Doanh thu</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-2" data-bs-toggle="tab" href="#2" role="tab"
                                    aria-controls="2" aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 14px; width: 120px;">Biểu đồ</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-3" data-bs-toggle="tab" href="#3" role="tab"
                                    aria-controls="3" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 14px; width: 120px;">Dịch vụ</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-4" data-bs-toggle="tab" href="#4" role="tab"
                                    aria-controls="4" aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 14px; width: 120px;">Nhân sự</span>
                                </a>
                            </li>
                            <!-- <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-6" data-bs-toggle="tab" href="#6" role="tab"
                                    aria-controls="6" aria-selected="true">
                                    <span class="badge bg-dark" style="font-size: 14px; width: 120px;">Số chứng
                                        thư</span>
                                </a>
                            </li> -->
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-5" data-bs-toggle="tab" href="#5" role="tab"
                                    aria-controls="5" aria-selected="true">
                                    <span class="badge bg-primary" style="font-size: 14px; width: 120px;">Thông
                                        tin</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="1" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <select class="form-select" name="year" [(ngModel)]="year">
                                            <option *ngFor="let item of DownloadService.listYear;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <select class="form-select" name="month" [(ngModel)]="month">
                                            <option *ngFor="let item of DownloadService.listMonth;"
                                                [value]="item.Display">
                                                {{item.Display}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm..."
                                            (click)="onSearchDoanhNghiepDichVuLichSu()">
                                            <i class="bi bi-search"></i>
                                        </a>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <div name="DoanhThuByMonth"
                                            style="font-weight: bold; color: limegreen; text-align: right; font-size: 20px;">
                                            {{doanhThuByMonth | number:
                                            '1.0-0'}} đồng
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="card mb-4 card-info">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">Doanh thu
                                                    <span
                                                        *ngIf="DoanhNghiepDichVuLichSuService.list">({{DoanhNghiepDichVuLichSuService.list.length}}
                                                        items)</span>
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table class="table table-striped m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th style="text-align: center;">No</th>
                                                                    <th style="text-align: center;">Gói cước</th>
                                                                    <th style="text-align: center;">Năm</th>
                                                                    <th style="text-align: center;">Tháng</th>
                                                                    <th style="text-align: center;">Doanh thu</th>
                                                                    <th style="text-align: center;">Mã thuê bao</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let element of DoanhNghiepDichVuLichSuService.list; index as i">
                                                                    <td>{{i + 1}}</td>
                                                                    <td>{{element.Name}}</td>
                                                                    <td style="text-align: right;">{{element.Year}}</td>
                                                                    <td style="text-align: right;">{{element.Month}}</td>
                                                                    <td style="text-align: right;"><b>{{element.GiaTien | number:
                                                                            '1.0-0'}}</b></td>
                                                                    <td>{{element.Note}}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="card mb-4 card-success">
                                            <div class="card-header">
                                                <h5 class="card-title text-white">So sánh
                                                    <span
                                                        *ngIf="ReportService.listReport001">({{ReportService.listReport001.length}}
                                                        items)</span>
                                                </h5>
                                            </div>
                                            <div class="card-body">
                                                <div class="table-outer">
                                                    <div class="table-responsive">
                                                        <table class="table table-striped m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th style="text-align: center;">No</th>
                                                                    <th style="text-align: center;">Dịch vụ</th>
                                                                    <th style="text-align: center;">Mã thuê bao</th>
                                                                    <th style="text-align: center;">{{month}}-{{year}}</th>
                                                                    <th style="text-align: center;">Liền kề</th>
                                                                    <th style="text-align: center;">Chênh lệch</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr
                                                                    *ngFor="let element of ReportService.listReport001; index as i">
                                                                    <td>{{i + 1}}</td>
                                                                    <td>
                                                                        {{element.DichVu}}
                                                                    </td>
                                                                    <td>{{element.MaThueBao}}</td>
                                                                    <td style="text-align: right;"><b>{{element.DoanhThu | number:
                                                                            '1.0-0'}}</b></td>
                                                                    <td style="text-align: right;"><b>{{element.DoanhThuLast | number:
                                                                            '1.0-0'}}</b></td>
                                                                    <td style="text-align: right;">
                                                                        <div *ngIf="element.ChenhLech>=0"
                                                                            style="color: limegreen; text-align: right; font-weight: bold;">
                                                                            {{element.ChenhLech | number:
                                                                            '1.0-0'}}</div>
                                                                        <div *ngIf="element.ChenhLech<0"
                                                                            style="color: red; text-align: right; font-weight: bold;">
                                                                            {{element.ChenhLech | number:
                                                                            '1.0-0'}}</div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="2" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <h3 style="text-align: center;"><b>Doanh thu tháng {{monthTitle}}-{{yearTitle}}
                                                (Đơn vị tính:
                                                đồng)</b></h3>
                                        <canvas baseChart [datasets]="ChartDataReportDoanhNghiep0021"
                                            [labels]="ChartLabelsReportDoanhNghiep0021"
                                            [chartType]="ChartTypeReportDoanhNghiep0021"
                                            [options]="ChartOptionsReportDoanhNghiep0021">
                                        </canvas>
                                        <canvas baseChart [datasets]="ChartDataReportDoanhNghiep0022"
                                            [labels]="ChartLabelsReportDoanhNghiep0022"
                                            [chartType]="ChartTypeReportDoanhNghiep0022"
                                            [options]="ChartOptionsReportDoanhNghiep0022">
                                        </canvas>
                                        <canvas baseChart [datasets]="ChartDataReportDoanhNghiep002"
                                            [labels]="ChartLabelsReportDoanhNghiep002"
                                            [chartType]="ChartTypeReportDoanhNghiep002"
                                            [colors]="ChartColorsReportDoanhNghiep002"
                                            [options]="ChartOptionsReportDoanhNghiep002">
                                        </canvas>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <h3 style="text-align: center;"><b>Doanh thu năm {{yearTitle}} (Đơn vị tính:
                                                đồng)</b></h3>
                                        <canvas baseChart [datasets]="ChartDataReportDoanhNghiep0041"
                                            [labels]="ChartLabelsReportDoanhNghiep0041"
                                            [chartType]="ChartTypeReportDoanhNghiep0041"
                                            [options]="ChartOptionsReportDoanhNghiep0041">
                                        </canvas>
                                        <canvas baseChart [datasets]="ChartDataReportDoanhNghiep0042"
                                            [labels]="ChartLabelsReportDoanhNghiep0042"
                                            [chartType]="ChartTypeReportDoanhNghiep0042"
                                            [options]="ChartOptionsReportDoanhNghiep0042">
                                        </canvas>
                                        <canvas baseChart [datasets]="ChartDataReportDoanhNghiep004"
                                            [labels]="ChartLabelsReportDoanhNghiep004"
                                            [chartType]="ChartTypeReportDoanhNghiep004"
                                            [colors]="ChartColorsReportDoanhNghiep004"
                                            [options]="ChartOptionsReportDoanhNghiep004">
                                        </canvas>
                                    </div>
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <canvas baseChart [datasets]="ChartDataReportDoanhNghiep003"
                                            [labels]="ChartLabelsReportDoanhNghiep003"
                                            [options]="ChartOptionsReportDoanhNghiep003"
                                            [plugins]="ChartPluginsReportDoanhNghiep003"
                                            [legend]="ChartLegendReportDoanhNghiep003"
                                            [chartType]="ChartTypeReportDoanhNghiep003">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="3" role="tabpanel">
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Gói cước
                                            <span
                                                *ngIf="DoanhNghiepDichVuService.list">({{DoanhNghiepDichVuService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table class="table table-striped m-0">
                                                    <thead>
                                                        <tr>
                                                            <th style="text-align: center;">No</th>
                                                            <th style="text-align: center;">Nhân viên AM</th>
                                                            <th style="text-align: center;">Gói cước</th>
                                                            <th style="text-align: center;">Ngày ký</th>
                                                            <th style="text-align: center;">Số hợp đồng</th>
                                                            <th style="text-align: center;">Mã thuê bao</th>
                                                            <th style="text-align: center;">Giá cước</th>
                                                            <th style="text-align: center;">Số tháng</th>
                                                            <th style="text-align: center;">Ghi chú</th>
                                                            <th style="text-align: center;">Sắp xếp</th>
                                                            <th style="text-align: center;">Kích hoạt</th>
                                                            <th style="text-align: center;">Lưu</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let element of DoanhNghiepDichVuService.list; index as i">
                                                            <td>{{i + 1}}</td>
                                                            <td>
                                                                <select class="form-select"
                                                                    name="NhanVienID{{element.ID}}"
                                                                    [(ngModel)]="element.NhanVienID">
                                                                    <option *ngFor="let item of NhanVienService.list;"
                                                                        [value]="item.ID">
                                                                        {{item.Name}}</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select class="form-select"
                                                                    name="DichVuID{{element.ID}}"
                                                                    [(ngModel)]="element.DichVuID">
                                                                    <option *ngFor="let item of DichVuService.list;"
                                                                        [value]="item.ID">
                                                                        {{item.Name}}</option>
                                                                </select>
                                                            </td>
                                                            <td><input name="NgayKyHopDong{{element.ID}}"
                                                                    style="text-align: right;" class="form-control"
                                                                    [ngModel]="element.NgayKyHopDong | date:'yyyy-MM-dd'"
                                                                    (ngModelChange)="onChangeDoanhNghiepDichVuNgayKyHopDong(element, $event)"
                                                                    [ngModelOptions]="{ updateOn: 'blur' }" type="date">
                                                            </td>
                                                            <td><input class="form-control" type="text"
                                                                    placeholder="Số hợp đồng" name="Code{{element.ID}}"
                                                                    [(ngModel)]="element.Code"></td>
                                                            <td><input class="form-control" type="text"
                                                                    placeholder="Mã thuê bao"
                                                                    name="MaThueBao{{element.ID}}"
                                                                    [(ngModel)]="element.MaThueBao"></td>
                                                            <td><input class="form-control" type="number"
                                                                    placeholder="Giá cước" name="GiaTien{{element.ID}}"
                                                                    [(ngModel)]="element.GiaTien"></td>
                                                            <td><input class="form-control" type="number"
                                                                    placeholder="Số tháng" name="SoThang{{element.ID}}"
                                                                    [(ngModel)]="element.SoThang"></td>
                                                            <td><input class="form-control" type="text"
                                                                    placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                    [(ngModel)]="element.Note"></td>
                                                            <td><input class="form-control" type="number"
                                                                    placeholder="Sắp xếp" name="SortOrder{{element.ID}}"
                                                                    [(ngModel)]="element.SortOrder"></td>
                                                            <td>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        name="Active{{element.ID}}"
                                                                        [(ngModel)]="element.Active">
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <a class="btn btn-info"
                                                                    (click)="onSaveDoanhNghiepThanhVien(element)"><i
                                                                        class="bi bi-sd-card"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="4" role="tabpanel">
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Nhân sự
                                            <span
                                                *ngIf="DoanhNghiepThanhVienService.list">({{DoanhNghiepThanhVienService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table class="table table-striped m-0">
                                                    <thead>
                                                        <tr>
                                                            <th style="text-align: center;">No</th>
                                                            <th style="text-align: center;">Thành viên</th>
                                                            <th style="text-align: center;">Nhân viên</th>
                                                            <th style="text-align: center;">Điện thoại</th>
                                                            <th style="text-align: center;">Email</th>
                                                            <th style="text-align: center;">Ngày sinh</th>
                                                            <th style="text-align: center;">CCCD</th>
                                                            <th style="text-align: center;">Ngày cấp</th>
                                                            <th style="text-align: center;">Nơi cấp</th>
                                                            <th style="text-align: center;">Kích hoạt</th>
                                                            <th style="text-align: center;">Lưu</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let element of DoanhNghiepThanhVienService.list; index as i">
                                                            <td>{{i + 1}}</td>
                                                            <td>
                                                                <select class="form-select"
                                                                    name="LoaiDoanhNghiepThanhVienID{{element.ID}}"
                                                                    [(ngModel)]="element.LoaiDoanhNghiepThanhVienID">
                                                                    <option
                                                                        *ngFor="let item of LoaiDoanhNghiepThanhVienService.list;"
                                                                        [value]="item.ID">
                                                                        {{item.Name}}</option>
                                                                </select>
                                                            </td>
                                                            <td><input class="form-control" type="text"
                                                                    placeholder="Nhân viên" name="Name{{element.ID}}"
                                                                    [(ngModel)]="element.Name"></td>
                                                            <td><input class="form-control" type="text"
                                                                    placeholder="Điện thoại"
                                                                    name="DienThoai{{element.ID}}"
                                                                    [(ngModel)]="element.DienThoai"></td>
                                                            <td><input class="form-control" type="text"
                                                                    placeholder="Email" name="Email{{element.ID}}"
                                                                    [(ngModel)]="element.Email"></td>
                                                            <td> <input name="NgaySinh{{element.ID}}"
                                                                    style="text-align: right;" class="form-control"
                                                                    [ngModel]="element.NgaySinh | date:'yyyy-MM-dd'"
                                                                    (ngModelChange)="onChangeDoanhNghiepThanhVienNgaySinh(element, $event)"
                                                                    [ngModelOptions]="{ updateOn: 'blur' }" type="date">
                                                            </td>
                                                            <td><input class="form-control" type="text"
                                                                    placeholder="CCCD" name="CCCD{{element.ID}}"
                                                                    [(ngModel)]="element.CCCD"></td>
                                                            <td> <input name="CCCD_NgayCap{{element.ID}}"
                                                                    style="text-align: right;" class="form-control"
                                                                    [ngModel]="element.CCCD_NgayCap | date:'yyyy-MM-dd'"
                                                                    (ngModelChange)="onChangeDoanhNghiepThanhVienCCCD_NgayCap(element, $event)"
                                                                    [ngModelOptions]="{ updateOn: 'blur' }" type="date">
                                                            </td>
                                                            <td><input class="form-control" type="text"
                                                                    placeholder="Nơi cấp"
                                                                    name="CCCD_NoiCap{{element.ID}}"
                                                                    [(ngModel)]="element.CCCD_NoiCap"></td>
                                                            <td>
                                                                <div class="form-check form-check-inline">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        name="Active{{element.ID}}"
                                                                        [(ngModel)]="element.Active">
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <a class="btn btn-info"
                                                                    (click)="onSaveDoanhNghiepThanhVien(element)"><i
                                                                        class="bi bi-sd-card"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="6" role="tabpanel">
                                <div class="card mb-4 card-dark">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Số chứng thư
                                            <span
                                            *ngIf="DoanhNghiepDichVuCAService.list">({{DoanhNghiepDichVuCAService.list.length}}
                                            items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #sortDoanhNghiepDichVuCA="matSort"
                                                    [dataSource]="dataSourceDoanhNghiepDichVuCA"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="No">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            No.
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;">
                                                            <a class="btn btn-success" title="Upload hồ sơ"
                                                                (click)="onAddDoanhNghiepDichVuCA(element.ID)">Upload hồ
                                                                sơ</a> | {{paginatorDoanhNghiepDichVuCA.pageSize *
                                                            paginatorDoanhNghiepDichVuCA.pageIndex + i +
                                                            1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsKetLuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hoàn thành
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsKetLuan{{element.ID}}"
                                                                    [(ngModel)]="element.IsKetLuan">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetLuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kết luận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div style="display: flex; white-space: nowrap;">
                                                                <b>{{element.KetLuan}}</b>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsHopDong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hợp đồng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsHopDong{{element.ID}}"
                                                                            [(ngModel)]="element.IsHopDong">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.HopDong && element.HopDong.length>10"
                                                                        target="_blank" href="{{element.HopDong}}"
                                                                        title="{{element.HoaDon}}"><i
                                                                            class="bi bi bi-eye-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsDonXinCapChungThuSo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phiếu đăng
                                                            ký
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsDonXinCapChungThuSo{{element.ID}}"
                                                                            [(ngModel)]="element.IsDonXinCapChungThuSo">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.DonXinCapChungThuSo && element.DonXinCapChungThuSo.length>10"
                                                                        target="_blank"
                                                                        href="{{element.DonXinCapChungThuSo}}"
                                                                        title="{{element.DonXinCapChungThuSo}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsCCCD">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>CCCD/HC
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsCCCD{{element.ID}}"
                                                                            [(ngModel)]="element.IsCCCD">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.CCCD && element.CCCD.length>10"
                                                                        target="_blank" href="{{element.CCCD}}"
                                                                        title="{{element.CCCD}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsGiayPhepKinhDoanh">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Giấy phép
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsGiayPhepKinhDoanh{{element.ID}}"
                                                                            [(ngModel)]="element.IsGiayPhepKinhDoanh">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.GiayPhepKinhDoanh && element.GiayPhepKinhDoanh.length>10"
                                                                        target="_blank"
                                                                        href="{{element.GiayPhepKinhDoanh}}"
                                                                        title="{{element.GiayPhepKinhDoanh}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsBienBanNghiemThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Biên bản
                                                            NT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsBienBanNghiemThu{{element.ID}}"
                                                                            [(ngModel)]="element.IsBienBanNghiemThu">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.BienBanNghiemThu && element.BienBanNghiemThu.length>10"
                                                                        target="_blank"
                                                                        href="{{element.BienBanNghiemThu}}"
                                                                        title="{{element.BienBanNghiemThu}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsHoaDon">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hoá đơn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsHoaDon{{element.ID}}"
                                                                            [(ngModel)]="element.IsHoaDon">
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6 col-sm-6 col-12">
                                                                    <a style="padding-top: 2px; padding-bottom: 0px; padding-left: 4px; padding-right: 4px; margin-top: 8px;"
                                                                        class="btn btn-success"
                                                                        *ngIf="element.HoaDon && element.HoaDon.length>10"
                                                                        target="_blank" href="{{element.HoaDon}}"
                                                                        title="{{element.HoaDon}}"><i
                                                                            class="bi bi-file-earmark-zip-fill"></i></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Note}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoChungThuCu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thư
                                                            cũ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.SoChungThuCu}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHieuLuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hiệu lực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHieuLuc |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayHetHan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hết hạn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.NgayHetHan |
                                                            date:'dd/MM/yyyy'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TenGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gói cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.TenGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại gói
                                                            cước
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.LoaiGoiCuoc}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThoiGianGoiCuoc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thời gian
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.ThoiGianGoiCuoc |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Email">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">{{element.Email}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienThoai">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">{{element.DienThoai}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanTaoYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tạo yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanTaoYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TaiKhoanDuyetYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Duyệt yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TaiKhoanDuyetYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="LoaiYeuCau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại yêu
                                                            cầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.LoaiYeuCau}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="GiaTien">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Doanh thu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element"
                                                            style="text-align: right;">
                                                            {{element.GiaTien |
                                                            number:
                                                            '1.0-0'}}
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="DoanhNghiepDichVuCAService.displayColumnsDoanhNghiep">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: DoanhNghiepDichVuCAService.displayColumnsDoanhNghiep;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #paginatorDoanhNghiepDichVuCA="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="5" role="tabpanel">
                                <div class="card mb-4 card-light">
                                    <div class="card-header">
                                        <h5 class="card-title">Thông tin
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
                                            <div class="row gx-4">
                                                <div class="col-lg-12 col-sm-12 col-12">
                                                    <a title="Danh sách" class="btn btn-success"
                                                        href="javascript:window.open('','_self').close();"><i
                                                            class="bi bi-arrow-left-square"></i></a>&nbsp;&nbsp;&nbsp;
                                                    <a title="Thêm mới" href="{{URLSub}}/0" class="btn btn-success"><i
                                                            class="bi bi-plus-square-dotted"></i></a>&nbsp;&nbsp;&nbsp;
                                                    <button [disabled]="!DoanhNghiepService.formData.Name"
                                                        title="Lưu thay đổi" type="submit" class="btn btn-info"><i
                                                            class="bi bi-sd-card"></i></button>
                                                </div>
                                            </div>
                                            <input name="ID" [(ngModel)]="DoanhNghiepService.formData.ID" type="hidden">
                                            <div class="row gx-4">
                                                <div class="col-lg-4 col-sm-12 col-12">
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Khách hàng</label>
                                                            <input name="Name"
                                                                [(ngModel)]="DoanhNghiepService.formData.Name"
                                                                placeholder="Khách hàng" type="text"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Mã số</label>
                                                            <input name="Code"
                                                                [(ngModel)]="DoanhNghiepService.formData.Code"
                                                                placeholder="Mã số" type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">UserName</label>
                                                            <input name="UserName"
                                                                [(ngModel)]="DoanhNghiepService.formData.UserName"
                                                                placeholder="UserName" type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Điện thoại</label>
                                                            <input name="DienThoai"
                                                                [(ngModel)]="DoanhNghiepService.formData.DienThoai"
                                                                placeholder="Điện thoại" type="text"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Email</label>
                                                            <input name="Email"
                                                                [(ngModel)]="DoanhNghiepService.formData.Email"
                                                                placeholder="Email" type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Địa chỉ</label>
                                                            <input name="DiaChi"
                                                                [(ngModel)]="DoanhNghiepService.formData.DiaChi"
                                                                placeholder="Địa chỉ" type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Mô tả</label>
                                                            <input name="Description"
                                                                [(ngModel)]="DoanhNghiepService.formData.Description"
                                                                placeholder="Mô tả" type="text" class="form-control">
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="col-lg-4 col-sm-12 col-12">
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Ngày cấp</label>
                                                            <input name="NgayCap"
                                                                [ngModel]="DoanhNghiepService.formData.NgayCap | date:'yyyy-MM-dd'"
                                                                (ngModelChange)="onChangeNgayCap($event)"
                                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Ngày thay đổi</label>
                                                            <input name="NgayDangKyThayDoi"
                                                                [ngModel]="DoanhNghiepService.formData.NgayDangKyThayDoi | date:'yyyy-MM-dd'"
                                                                (ngModelChange)="onChangeNgayDangKyThayDoi($event)"
                                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Ngành nghề chính</label>
                                                            <select class="form-select" name="NganhNgheKinhDoanhChinhID"
                                                                [(ngModel)]="DoanhNghiepService.formData.NganhNgheKinhDoanhChinhID">
                                                                <option
                                                                    *ngFor="let item of NganhNgheKinhDoanhService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Vốn điều lệ</label>
                                                            <input name="VonDieuLe"
                                                                [(ngModel)]="DoanhNghiepService.formData.VonDieuLe"
                                                                placeholder="Vốn điều lệ" type="number"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Số lao động</label>
                                                            <input name="SoLuongLaoDong"
                                                                [(ngModel)]="DoanhNghiepService.formData.SoLuongLaoDong"
                                                                placeholder="Số lao động" type="number"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Ngành nghề</label>
                                                            <input name="NganhNgheKinhDoanh"
                                                                [(ngModel)]="DoanhNghiepService.formData.NganhNgheKinhDoanh"
                                                                placeholder="Ngành nghề" type="text"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Ghi chú</label>
                                                            <input name="Note"
                                                                [(ngModel)]="DoanhNghiepService.formData.Note"
                                                                placeholder="Ghi chú" type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-sm-12 col-12">
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Phòng ban</label>
                                                            <select class="form-select" name="PhongBanID"
                                                                [(ngModel)]="DoanhNghiepService.formData.PhongBanID">
                                                                <option *ngFor="let item of PhongBanService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Nhân viên AM</label>
                                                            <select class="form-select" name="NhanVienID"
                                                                [(ngModel)]="DoanhNghiepService.formData.NhanVienID">
                                                                <option *ngFor="let item of NhanVienService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Huyện</label>
                                                            <select class="form-select" name="HuyenID"
                                                                [(ngModel)]="DoanhNghiepService.formData.HuyenID">
                                                                <option *ngFor="let item of HuyenService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Xã</label>
                                                            <select class="form-select" name="XaID"
                                                                [(ngModel)]="DoanhNghiepService.formData.XaID">
                                                                <option *ngFor="let item of XaService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Loại doanh nghiệp</label>
                                                            <select class="form-select" name="LoaiDoanhNghiepID"
                                                                [(ngModel)]="DoanhNghiepService.formData.LoaiDoanhNghiepID">
                                                                <option
                                                                    *ngFor="let item of LoaiDoanhNghiepService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-12 col-sm-4 col-12">
                                                        <div class="mb-3">
                                                            <label class="form-label">Trạng thái</label>
                                                            <select class="form-select" name="LoaiTrangThaiID"
                                                                [(ngModel)]="DoanhNghiepService.formData.LoaiTrangThaiID">
                                                                <option *ngFor="let item of LoaiTrangThaiService.list;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>